export const adaptGetIncumbenciasSincro = (arrIncumbencias) => {
    return arrIncumbencias.map(ctElement => {
        let formatedResponse = {}

        formatedResponse.terna = ctElement.terna_id ? {
            id: ctElement.terna_id,
            titulo: { id: ctElement.titulo_id, nombre: ctElement.titulo_nombre },
            procedencia: { id: ctElement.procedencia_id, nombre: ctElement.procedencia_nombre.trim() },
            resolucion: { id: ctElement.resolucion_id, nombre: ctElement.resolucion_nombre },
        } : null

        formatedResponse.terna2 = ctElement.terna2_id ? {
            id: ctElement.terna2_id,
            titulo: { id: ctElement.titulo2_id, nombre: ctElement.titulo2_nombre },
            procedencia: { id: ctElement.procedencia2_id, nombre: ctElement.procedencia2_nombre.trim() },
            resolucion: { id: ctElement.resolucion2_id, nombre: ctElement.resolucion2_nombre },
        } : null

        formatedResponse.terna3 = ctElement.terna3_id ? {
            id: ctElement.terna3_id,
            titulo: { id: ctElement.titulo3_id, nombre: ctElement.titulo3_nombre },
            procedencia: { id: ctElement.procedencia3_id, nombre: ctElement.procedencia3_nombre.trim() },
            resolucion: { id: ctElement.resolucion3_id, nombre: ctElement.resolucion3_nombre },
        } : null

        formatedResponse.cantidad_incumbencias = ctElement.cantidad_incumbencias

        return formatedResponse
    })
}

export const adaptGetIncumbenciasPendientesSincro = (arrIncumbencias) => {
    const arrAdapted = adaptGetIncumbenciasSincro(arrIncumbencias)

    return arrAdapted
}

export const adaptGetIncumbenciasSincroDetail = (arrIncumbencias) => {
    return arrIncumbencias.map(ctElement => {
        let formatedResponse = {}

        formatedResponse.asignatura = ctElement.asignatura_id ? {
            id: ctElement.asignatura_id,
            nombre: ctElement.asignatura_nombre,
        } : null

        formatedResponse.apendice = ctElement.apendice_id ? {
            id: ctElement.apendice_id,
            nombre: ctElement.apendice_nombre,
        } : null

        formatedResponse.area = ctElement.area_id ? {
            id: ctElement.area_id,
            nombre: ctElement.area_nombre,
        } : null

        formatedResponse.cargo = ctElement.cargo_id ? {
            id: ctElement.cargo_id,
            nombre: ctElement.cargo_nombre,
        } : null

        formatedResponse.especialidad = ctElement.especialidad_id ? {
            id: ctElement.especialidad_id,
            nombre: ctElement.especialidad_nombre,
        } : null

        formatedResponse.tipoIncumbencia = ctElement.tipo_incumbencia_id ? {
            id: ctElement.tipo_incumbencia_id,
            nombre: ctElement.tipo_incumbencia_nombre,
        } : null

        formatedResponse.tipoEscuela = ctElement.tipo_escuela_id ? {
            id: ctElement.tipo_escuela_id,
            nombre: ctElement.tipo_escuela_nombre,
        } : null

        formatedResponse.id = ctElement.id
        formatedResponse.puntaje = ctElement.puntaje
        formatedResponse.sumaOT = ctElement.suma_ot
        formatedResponse.folio = ctElement.folio


        return formatedResponse
    })
}

export const adaptGetIncumbenciasSincroPendientesDetail = (arrIncumbencias) => {
    return arrIncumbencias.map(ctElement => {
        let formatedResponse = {}

        formatedResponse.asignatura = ctElement.asignatura_id ? {
            id: ctElement.asignatura_id,
            nombre: ctElement.asignatura_nombre,
        } : null

        formatedResponse.apendice = ctElement.apendice_id ? {
            id: ctElement.apendice_id,
            nombre: ctElement.apendice_nombre,
        } : null

        formatedResponse.area = ctElement.area_id ? {
            id: ctElement.area_id,
            nombre: ctElement.area_nombre,
        } : null

        formatedResponse.cargo = ctElement.cargo_id ? {
            id: ctElement.cargo_id,
            nombre: ctElement.cargo_nombre,
        } : null

        formatedResponse.especialidad = ctElement.especialidad_id ? {
            id: ctElement.especialidad_id,
            nombre: ctElement.especialidad_nombre,
        } : null

        formatedResponse.tipoIncumbencia = ctElement.tipo_incumbencia_id ? {
            id: ctElement.tipo_incumbencia_id,
            nombre: ctElement.tipo_incumbencia_nombre,
        } : null

        formatedResponse.tipoEscuela = ctElement.tipo_escuela_id ? {
            id: ctElement.tipo_escuela_id,
            nombre: ctElement.tipo_escuela_nombre,
        } : null

        formatedResponse.tipoIncumbencia = ctElement.tipo_incumbencia_id ? {
            id: ctElement.tipo_incumbencia_id,
            nombre: ctElement.tipo_incumbencia_nombre,
        } : null

        formatedResponse.id = ctElement.id
        formatedResponse.incumbencia_id = ctElement.incumbencia_id
        formatedResponse.puntaje = ctElement.puntaje
        formatedResponse.sumaOT = ctElement.suma_ot
        formatedResponse.folio = ctElement.folio

        return formatedResponse
    })

}