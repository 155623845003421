import React from "react";
import { useLocation } from "react-router-dom";
import { routePaths, typeOfActions } from "../../../utils/Constants";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { info, success } from "../../../store/alerts/alertActions";
import { useEffect, useRef } from "react";
import { EquivalenciaABM } from "./EquivalenciaABM";
import { patchEquivalente } from "../equivalenciaService";


export const EquivalenciaABMEdit = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const isMountedRef = useRef(null);

    const handleEdit = async (arrOriginalesSeleccionados, arrRelacionesSeleccionadas, inputsForm) => {
        try {
            const params = {
                original: arrOriginalesSeleccionados[0].id,
                equivalente: arrRelacionesSeleccionadas[0].id,
                folio: inputsForm.folio,
                apendice_id: inputsForm.apendice.id,
                id: inputsForm.relacion_id,
            }

            const response = await patchEquivalente(params, isMountedRef);
            history.push(routePaths.ABM_TITULOS_EQUIVALENTES_SEARCH)
            dispatch(success("Se edito la relacion equivalente correctamente."));
        }
        catch (err) {
            dispatch(info("No pudo editarse la relacion equivalente."));
        }
    }

    useEffect(() => {
        isMountedRef.current = true;
        return () => (isMountedRef.current = false);
    }, []);

    return (
        <EquivalenciaABM
            handleSubmit={handleEdit}
            relacion={location?.state?.relacionEquivalente}
            title="Editar relación entre títulos (Equivalente)"
            typeOfAction={typeOfActions.EDIT}
        />
    );
};

