import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import CuadroSeleccion from '../../../titulos/shared/CuadroSeleccion'
import { incumbenciasConstants, tipoDocumentoClonador } from '../../../utils/Constants'
import { titulosIcon } from '../../../utils/menuDashboardOptions'
import { IncumbenciasABMTable } from '../../abm/IncumbenciasABMTable'
import { ClonadorCargoRow } from './ClonadorCargoRow'
import { ClonadorCargoTable } from './ClonadorCargoTable'

export const ClonadorCargosCards = ({
    original,
    destino,
    onClickSeleccionarOriginal,
    onClickSeleccionarDestino,
    handleAgregarVarianteCargo,
    invertirTernas,
    validated,
    getSelectCondicionComponent,
}) => {

    const renderSelect = (elemento, tipo, handleClickFinalizarCargaFunction) => {
        return (
            <ClonadorCargoRow
                elemento={elemento}
                handleClickFinalizarCargaFunction={handleClickFinalizarCargaFunction}
                tipo={tipo}
                validated={validated}
            />
        )
    }

    const renderTableSelect = (elementos, handleClickFinalizarCargaFunction, tipoDocumento) => {
        return <ClonadorCargoTable
            elementos={elementos}
            handleClickFinalizarCargaFunction={handleClickFinalizarCargaFunction}
            tipoDocumento={tipoDocumento}
            renderSelect={renderSelect}
        />
    }

    const errorValidatedMessageOriginal =
        !(original.length > 0) ?
            "El campo es obligatorio" :
            !(original[0]?.variantes?.length > 0) ?
                "El formulario presenta campos sin completar"
                : false

    const errorValidatedMessageDestino =
        !(destino.length > 0) ?
            "El campo es obligatorio" :
            !(destino[0]?.variantes?.length > 0) ?
                "El formulario presenta campos sin completar"
                : false

    return (
        <div>
            <Row className={`form-group mb-3 position-relative ${!(original?.length > 0) ? "container mx-auto" : "col-fluid"}`} style={{ zIndex: 9 }}>
                <Col xs={12} md={12} lg={12}>
                    <CuadroSeleccion
                        title={"Cargo original"}
                        secondButtonText={"Cambiar"}
                        secondAdditionalContent={<div>
                            
                        </div>}
                        component
                        icon={titulosIcon}
                        text={"Parece que todavía no hay un cargo seleccionado"}
                        onClick={onClickSeleccionarOriginal}
                        validated={validated}
                        elementos={original}
                        renderTable={(elementos) => renderTableSelect(elementos, handleAgregarVarianteCargo, tipoDocumentoClonador.ORIGINAL)}
                        ocultarBuscador={false}
                        firstButtonText={"Buscar"}
                        errorValidatedMessage={errorValidatedMessageOriginal}
                        getSecondAdditionalContent={() => getSelectCondicionComponent({
                            componentLabel: "Condicion anterior",
                            componentKey: "condicion_origen",
                            checkRequiredKey: "condicion_destino",
                        })}
                    />
                    {(original.length > 0 || destino.length > 0) &&
                        <div
                            className={`${(original.length > 0 && destino.length > 0) ? "w-100" : "container"} d-flex flex-row-reverse`}
                            style={{ position: "absolute", bottom: "-0.5rem", transform: "translate(-50%, 50%)", left: "50%", paddingRight: (original.length > 0 && destino.length > 0) ? "1rem" : "2rem" }}>
                            <Button
                                onClick={(invertirTernas)}
                                type="submit"
                                variant={"primary"}
                            >
                                <FontAwesomeIcon icon={faArrowsAltV} /> Invetir Ternas
                            </Button>
                        </div>
                    }
                </Col>
            </Row>
            <Row className={`form-group ${!(destino?.length > 0) ? "container mx-auto" : "col-fluid"}`}>
                <Col xs={12} md={12} lg={12}>
                    <CuadroSeleccion
                        title={"Cargo destino"}
                        secondButtonText={"Cambiar"}
                        icon={titulosIcon}
                        text={"Parece que todavía no hay un cargo seleccionado"}
                        onClick={onClickSeleccionarDestino}
                        validated={validated}
                        elementos={destino}
                        renderTable={(elementos) => renderTableSelect(elementos, handleAgregarVarianteCargo, tipoDocumentoClonador.DESTINO)}
                        ocultarBuscador={false}
                        firstButtonText={"Buscar"}
                        errorValidatedMessage={errorValidatedMessageDestino}
                        getSecondAdditionalContent={() => getSelectCondicionComponent({
                            componentLabel: "Condicion nueva",
                            componentKey: "condicion_destino",
                            checkRequiredKey: "condicion_origen",
                            componentPuntajeKey: "puntaje_ot_destino",
                            withPuntaje: true,
                        })}
                    />
                </Col>
            </Row>
        </div>
    )
}


