import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { clear, error, info, success } from "../../../store/alerts/alertActions";
import { RelacionABMForm } from "../../shared/RelacionABMForm";
import { SearchRelacion } from "../../shared/SearchRelacion";
import { routePaths, typeOfActions } from "../../../utils/Constants";
import * as apendiceService from "../../../apendice/ApendiceService";
import { ITFSelectRow } from "./ITFSelectRow";
import { ITFABMTable } from "./ITFABMTable";
import { postTitulosITF } from "../ITFService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ITFABM = ({
  title = "Crear relación entre títulos (ITF)",
  relacion,
  typeOfAction,
  handleSubmit,
}) => {
  const ITFABM_SECTIONS = {
    SELECCIONAR_ORIGINAL: "SELECCIONAR_ORIGINAL",
    SELECCIONAR_RELACIONES: "SELECCIONAR_RELACIONES",
    CREAR_RELACION: "CREAR_RELACION",
  }

  const [arrOriginalesSeleccionados, setArrOriginalesSeleccionados] = useState(null);
  const [arrRelacionesSeleccionadas, setArrRelacionesSeleccionadas] = useState(null);
  const [inputsForm, setInputsForm] = useState([]);

  const [selectDocument, setSelectDocument] = useState()

  const isMountedRef = useRef(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const isSeleccionarOriginal = selectDocument == ITFABM_SECTIONS.SELECCIONAR_ORIGINAL

  const returnCallBack = () => {
    dispatch(clear())
    setSelectDocument(null)
  }

  const getCurrentApendice = async () => {
    apendiceService.getCurrentApendice().then(result => {
      if (isMountedRef.current) {
        setInputsForm(data => ({ ...data, apendice: result }))
      }
    });
  };

  const loadCrearForm = () => {
    getCurrentApendice()
  }

  const loadEditarForm = () => {
    setArrOriginalesSeleccionados([relacion.original])
    setArrRelacionesSeleccionadas([relacion.relacionado.terna])
    setInputsForm({
      relacion_id: relacion.relacionado.id,
      apendice: relacion.relacionado.apendice,
      folio: relacion.relacionado.folio
    })
  }

  const renderSearchTable = (
    titulos,
    cantidadTitulos,
    page,
    changePage,
    renderTableRows
  ) => {
    return (
      <ITFABMTable
        titulos={titulos}
        renderTableRows={renderTableRows}
        cantidadTitulos={cantidadTitulos}
        page={page}
        changePage={changePage}
      />
    );
  };

  const renderSearchRow = (titulo, seleccionados, onChangeSeleccionado) => {
    return (
      <ITFSelectRow
        titulo={titulo}
        onChangeSeleccionado={onChangeSeleccionado}
        seleccionados={seleccionados}
        visibleCheckbox={false}
        visibleRadio={true}
      />
    );
  };

  const renderElementoABM = (elemento) => {
    return (
      <ITFSelectRow
        titulo={elemento}
        visibleCheckbox={false}
        visibleRadio={false}
      />
    );
  };

  const renderTableOriginalABM = (titulos) => {
    return (
      <ITFABMTable
        titulos={titulos}
        renderTableRows={elementos => elementos.map(elemento => renderElementoABM(elemento))}
        visibleCheckbox={false}
        visiblePaginacion={false}
        visibleInputs={false}
      />
    );
  };

  const renderTableRelacionadosABM = (titulos) => {
    return (
      <ITFABMTable
        titulos={titulos}
        renderTableRows={elementos => elementos.map(elemento => renderElementoABM(elemento))}
        visibleCheckbox={false}
        visiblePaginacion={false}
        visibleInputs={false}
      />
    );
  };

  const checkAndRemoveIfSameAs = (arrSeleccionados) => {
    return arrSeleccionados.filter(elemento => !arrOriginalesSeleccionados.some(ctOriginal => ctOriginal.id == elemento.id))
  }

  const setterValidations = (arrSeleccionados) => {
    return checkAndRemoveIfSameAs(arrSeleccionados)
  }

  const handleCreate = async (arrOriginalesSeleccionados, arrRelacionesSeleccionadas, inputsForm) => {
    const params = {
      terna_original: arrOriginalesSeleccionados[0].id,
      relacionados: arrRelacionesSeleccionadas.map(ctRelacion => ({
        terna_id: ctRelacion.id,
        folio: inputsForm.folio,
        apendice: inputsForm.apendice.id
      }))
    }

    try {
      const response = await postTitulosITF(params, isMountedRef);
      if (response.agregados.length == 1) {
        history.push(routePaths.ABM_TITULOS_ITF_SEARCH)
        dispatch(success("Se creo la relacion ITF correctamente."));
      } else {
        dispatch(info("No pudo crearse una nueva relacion: Ya existe la relacion ITF."));
      }
    }
    catch (err) {
      dispatch(error(err.response.data.message))
    }
  }


  useEffect(() => {
    if (typeOfAction == typeOfActions.CREATE) loadCrearForm();
    if (typeOfAction == typeOfActions.EDIT || typeOfAction == typeOfActions.APPROVAL) loadEditarForm();

    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  }, []);

  return (
    <>
      {selectDocument ?
        <SearchRelacion
          title={isSeleccionarOriginal ? "Buscar título principal" : "Buscar título ITF"}
          returnCallBack={returnCallBack}
          setterValidations={isSeleccionarOriginal ? null : setterValidations}
          setter={isSeleccionarOriginal ? setArrOriginalesSeleccionados : setArrRelacionesSeleccionadas}
          // esMultipleSeleccion={selectDocument != ITFABM_SECTIONS.SELECCIONAR_ORIGINAL}
          esMultipleSeleccion={false}
          arrCurrentSeleccionado={isSeleccionarOriginal ? arrOriginalesSeleccionados : arrRelacionesSeleccionadas}
          renderRow={renderSearchRow}
          renderTable={renderSearchTable}
        />
        :
        <RelacionABMForm
          title={title}
          inputsForm={inputsForm}
          setInputsForm={setInputsForm}
          handleSubmit={handleSubmit ?? handleCreate}
          typeOfAction={typeOfAction}
          arrOriginalesSeleccionados={arrOriginalesSeleccionados}
          arrRelacionesSeleccionadas={arrRelacionesSeleccionadas}
          changeToSeleccionarOriginalSeccion={() => setSelectDocument(ITFABM_SECTIONS.SELECCIONAR_ORIGINAL)}
          changeToSeleccionarRelacionesSeccion={() => setSelectDocument(ITFABM_SECTIONS.SELECCIONAR_RELACIONES)}
          renderTableOriginal={renderTableOriginalABM}
          renderTableRelacionados={renderTableRelacionadosABM}
        />
      }
    </>
  );
};