import React, { useEffect, useState } from 'react'
import { ScreenWithFabButtons } from '../../../ui/ScreenWithFabButtons'
import { getUnificacionesTerna } from '../../TernaService'
import { useDispatch } from 'react-redux';
import { error } from '../../../store/alerts/alertActions';
import { Alert, Col, Row } from 'react-bootstrap';
import { Loader } from '../../../ui/Loader';
import { UnificadorTable } from './UnificadorTable';
import { UnificadorFormSearch } from './UnificadorFormSearch';

export const UnificadorTernasSearch = () => {
    const [elementos, setElementos] = useState(null);
    const [page, setPage] = useState(1);
    const [cantidad, setCantidad] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const searchUnificaciones = async (newFiltros = {}) => {
        try {
            setIsLoading(true);
            const response = await getUnificacionesTerna(newFiltros);
            setPage(response.page);
            setElementos(response.elementos);
            setCantidad(response.cantidad);
        }
        catch (err) {
            dispatch(error("No se pudieron cargar las unificaciones"))
        }
        finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        searchUnificaciones()
    }, [])

    return (
        <ScreenWithFabButtons
            hidePlusButton={false}
            hideSearchButton={false}
            hideCleanButton={false}
            searchButtonDisabled={isLoading}
            cleanButtonDisabled={isLoading || !elementos}
        >
            <Row>
                <Col>
                    <h1>Unificador de ternas</h1>
                    <UnificadorFormSearch
                        setElementos={setElementos}
                        onSubmit={searchUnificaciones}
                    // filtros={filtrosGuardados}
                    ></UnificadorFormSearch>
                    {elementos && !isLoading && (
                        elementos.length > 0 ?
                            <UnificadorTable
                                elementos={elementos}
                                cantidad={cantidad}
                                page={page}
                            // changePage={handleChangePage}
                            // onSubmit={searchTernas}
                            ></UnificadorTable>
                            :
                            <Row className="form-group">
                                <Col>
                                    <Alert variant="info">No se encontraron unificaciones.</Alert>
                                </Col>
                            </Row>
                    )}

                    {
                        isLoading && <Loader></Loader>
                    }
                </Col>
            </Row>
        </ScreenWithFabButtons>
    )
}
