import axios from 'axios'
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux"

import { apiURLs } from '../utils/Constants'

const API_LOGIN = apiURLs.API_URL + '/login/google/validate'

const login = async (responseGoogle) => {
    try {
        //si puedo loguearme guardo el tokenmas que
        const response = await axios.post(API_LOGIN, {},
            {
                headers: {
                    'Authorization': `token ${responseGoogle.accessToken}`
                }
            }
        )
        var decoded = jwt_decode(response.data.token);
        sessionStorage.setItem("user", JSON.stringify({
            userid: decoded.sub,
            nombre: decoded.data.nombre,
            apellido: decoded.data.apellido,
            imagen: responseGoogle.profileObj.imageUrl,
            token: response.data.token,
            refreshToken: response.data.refreshToken,
            permisos: decoded.data.permisos

        })
        )
    } catch (error) {
        throw error
    }

}

const isLogged = () => {
    //si hay token el usuario esta logueado
    const user = JSON.parse(sessionStorage.getItem("user"))
    return !!user
}

const getToken = () => {
    //si hay token el usuario esta logueado
    const user = JSON.parse(sessionStorage.getItem("user"))
    return user.token
}

const getImage = () => {
    const user = JSON.parse(sessionStorage.getItem("user"))
    return user.imagen
}
const getNombreCompleto = () => {
    const user = JSON.parse(sessionStorage.getItem("user"))
    return user.nombre + " " + user.apellido
}

const getUserId = () => {
    const user = JSON.parse(sessionStorage.getItem("user"))
    return user.userid
}

const validatePermiso = (permiso) => {
    const user = JSON.parse(sessionStorage.getItem("user"))
    const key = Object.keys(permiso)[0]
    const nivel = permiso[key]
    return user.permisos.hasOwnProperty(key) && user.permisos[key] >= nivel
}

const getPublicContent = () => {
    return axios.get(apiURLs.API_URL + "/prueba");
}

const logout = () => {
    //borro token
    sessionStorage.removeItem("user")
}

const useAuthorization = (permisos) => {
    const user = useSelector(state => state.auth.user)

    if (isLogged()) {
        let hasAuthorization = true;
        for (let permiso in permisos) {
            hasAuthorization = user?.permisos?.hasOwnProperty(permiso) && user?.permisos[permiso] >= permisos[permiso]
            if (!hasAuthorization) {
                break;
            }
        };
        return hasAuthorization
    }
}

export {
    login,
    isLogged,
    logout,
    getToken,
    getImage,
    getNombreCompleto,
    validatePermiso,
    getPublicContent,
    getUserId,
    useAuthorization
}