import { apiURLs } from "../utils/Constants";
import requestService from "../utils/request.service";
import { adaptGetIncumbenciasPendientesSincro, adaptGetIncumbenciasSincro, adaptGetIncumbenciasSincroDetail, adaptGetIncumbenciasSincroPendientesDetail } from "./SincronizacionAdapter";

//#region Sincro Ternas

const getTernas = async (params) => {
  let response = await requestService.sendRequest('GET', apiURLs.API_SINCRO_TERNAS_BUSCAR, params)
  return response.data;
}

const getTernasPendientes = async (params) => {
  let response = await requestService.sendRequest('GET', apiURLs.API_SINCRO_TERNAS_BUSCAR_PENDIENTE, params)
  return response.data;
}

const crearSincronismoTernas = async (params) => {
  let response = await requestService.sendRequest('POST', apiURLs.API_SINCRO_TERNAS, params)
  return response.data;
}

//#region Sincro Incumbencias
const getIncumbencias = async (params) => {
  let response = await requestService.sendRequest('GET', apiURLs.API_SINCRO_INCUMBENCIAS_BUSCAR, params)

  return {
    elementos: adaptGetIncumbenciasSincro(response.data.elementos),
    cantidad: response.data.cantidad,
    page: response.data.page,
  };
}

const getIncumbenciasPendientesDetail = async (params) => {
  console.log("getIncumbenciasPendientesDetail", { params })
  let response = await requestService.sendRequest('GET', apiURLs.API_SINCRO_INCUMBENCIAS_BUSCAR_PENDIENTE_DETAIL, params)

  return {
    elementos: adaptGetIncumbenciasSincroPendientesDetail(response.data.elementos),
  };
}

const getIncumbenciasDetail = async (params) => {
  console.log("getIncumbenciasDetail", { params })
  let response = await requestService.sendRequest('GET', apiURLs.API_SINCRO_INCUMBENCIAS_BUSCAR_DETAIL, params)

  return {
    elementos: adaptGetIncumbenciasSincroDetail(response.data.elementos),
  };
}

const getIncumbenciasPendientes = async (params) => {
  let response = await requestService.sendRequest('GET', apiURLs.API_SINCRO_INCUMBENCIAS_BUSCAR_PENDIENTE, params)
  return {
    elementos: adaptGetIncumbenciasPendientesSincro(response.data.elementos),
    cantidad: response.data.cantidad,
    page: response.data.page,
  };
}

const crearSincronismoIncumbencias = async (params) => {
  let response = await requestService.sendRequest('POST', apiURLs.API_SINCRO_INCUMBENCIAS, params)
  return response.data;
}

//#region Sincro Asignaturas

const getAsignaturas = async (params) => {
  let response = await requestService.sendRequest('GET', apiURLs.API_SINCRO_ASIGNATURAS_BUSCAR, params)
  return response.data;
}

const getAsignaturasPendientes = async (params) => {
  let response = await requestService.sendRequest('GET', apiURLs.API_SINCRO_ASIGNATURAS_BUSCAR_PENDIENTE, params)
  return response.data;
}

const crearSincronismoAsignaturas = async (params) => {
  let response = await requestService.sendRequest('POST', apiURLs.API_SINCRO_ASIGNATURAS, params)
  return response.data;
}

//#region Sincro Especialidad

const getEspecialidades = async (params) => {
  let response = await requestService.sendRequest('GET', apiURLs.API_SINCRO_ESPECIALIDADES_BUSCAR, params)
  return response.data;
}

const getEspecialidadesPendientes = async (params) => {
  let response = await requestService.sendRequest('GET', apiURLs.API_SINCRO_ESPECIALIDADES_BUSCAR_PENDIENTE, params)
  return response.data;
}

const crearSincronismoEspecialidades = async (params) => {
  let response = await requestService.sendRequest('POST', apiURLs.API_SINCRO_ESPECIALIDADES, params)
  return response.data;
}

//#region Sincro Cargo

const getCargos = async (params) => {
  let response = await requestService.sendRequest('GET', apiURLs.API_SINCRO_CARGOS_BUSCAR, params)
  return response.data;
}

const getCargosPendientes = async (params) => {
  let response = await requestService.sendRequest('GET', apiURLs.API_SINCRO_CARGOS_BUSCAR_PENDIENTE, params)
  return response.data;
}

const crearSincronismoCargos = async (params) => {
  let response = await requestService.sendRequest('POST', apiURLs.API_SINCRO_CARGOS, params)
  return response.data;
}

export {
  crearSincronismoCargos,
  crearSincronismoTernas,
  crearSincronismoIncumbencias,
  crearSincronismoEspecialidades,
  getCargos,
  getCargosPendientes,
  getEspecialidades,
  getEspecialidadesPendientes,
  getIncumbencias,
  getIncumbenciasPendientes,
  getTernas,
  getTernasPendientes,
  getAsignaturas,
  getAsignaturasPendientes,
  crearSincronismoAsignaturas,
  getIncumbenciasDetail,
  getIncumbenciasPendientesDetail,
};

