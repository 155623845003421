import React from 'react'
import { incumbenciasConstants } from '../../../utils/Constants'
import { IncumbenciasSelectRow } from '../../abm/IncumbenciasSelectRow'

export const ClonadorCargoRow = ({ elemento, tipo, handleClickFinalizarCargaFunction, validated }) => {

  return (
    <IncumbenciasSelectRow
      elemento={elemento}
      mainElement={tipo}
      tipo={tipo}
      showButtons={true}
      handleClickFinalizarCargaFunction={handleClickFinalizarCargaFunction}
      showCantidadIncumbencias={true}
      showActionsCol={false}
      // renderCustomDetailModal={() => "Modal"}
      validated={validated}
      hideTipoEscuela={true}
    />
  )
}
