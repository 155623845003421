
import { Col, Row } from "react-bootstrap";
import cargos from "../images/cargos-icon.svg";
import especialidades from "../images/especialidades-icon.svg";
import titulos from "../images/titulos-icon.svg";
import { ShortcutButton } from "../ui/ShortcutButton";
import { routePaths } from "../utils/Constants";

export const SincroDash = () => {
    return (
        <div>
            <Row className="justify-content-center">
                <Col xs={6} md={4} lg={3}>
                    <div className="d-flex justify-content-center my-4">
                        <ShortcutButton to={routePaths.SINCRO_PANEL_TERNAS} icon={titulos} title="Ternas" />
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <div className="d-flex justify-content-center my-4">
                        <ShortcutButton to={routePaths.SINCRO_PANEL_INCUMBENCIAS} icon={cargos} title="Incumbencias" />
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <div className="d-flex justify-content-center my-4">
                        <ShortcutButton to={routePaths.SINCRO_PANEL_CARGOS} icon={cargos} title="Cargos" />
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <div className="d-flex justify-content-center my-4">
                        <ShortcutButton to={routePaths.SINCRO_PANEL_ASIGNATURAS} icon={cargos} title="Asignaturas" />
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <div className="d-flex justify-content-center my-4">
                        <ShortcutButton to={routePaths.SINCRO_PANEL_ESPECIALIDADES} icon={especialidades} title="Especialidades" />
                    </div>
                </Col>
            </Row>
        </div>
    );
}