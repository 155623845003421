import React from "react";
import { Accordion, Card, Col, Row, Table } from "react-bootstrap";

export const UnificacionAccordion = ({
    unificacion,
}) => {

    return (
        <Accordion.Collapse eventKey="0">
            <Card.Body>
                <Row className="form-group">
                    <Col>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th width="35%">Nombre del título</th>
                                    <th width="30%">Procedencia</th>
                                    <th width="20%">Resolución</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {unificacion.unificaciones.map(unificacion =>
                                    <tr key={unificacion.id}>
                                        <td>{unificacion.terna.titulo.nombre}</td>
                                        <td>{unificacion.terna.procedencia.nombre}</td>
                                        <td>{unificacion.terna.resolucion.nombre}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card.Body>
        </Accordion.Collapse>
    );
};