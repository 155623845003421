import React from "react";
import { useLocation } from "react-router-dom";
import { routePaths, typeOfActions } from "../../../utils/Constants";
import { ITFABM } from "./ITFABM";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { info, success } from "../../../store/alerts/alertActions";
import { patchTitulosITF } from "../ITFService";
import { useEffect } from "react";
import { useRef } from "react";


export const ITFABMEdit = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const isMountedRef = useRef(null);

    const handleEdit = async (arrOriginalesSeleccionados, arrRelacionesSeleccionadas, inputsForm) => {
        try {
            const params = {
                editadas: arrRelacionesSeleccionadas.map(ctRelacion => ({
                    relacion_id: inputsForm.relacion_id,
                    folio: inputsForm.folio,
                    apendice_id: inputsForm.apendice.id,
                }))
            }

            const response = await patchTitulosITF(params, isMountedRef);
            if (response.editados.length == 1) {
                history.push(routePaths.ABM_TITULOS_ITF_SEARCH)
                dispatch(success("Se edito la relacion ITF correctamente."));
            } else {
                dispatch(info("No pudo editarse la relacion ITF."));
            }
        }
        catch (err) {
            dispatch(info("No pudo editarse la relacion ITF."));
        }
    }

    useEffect(() => {
        isMountedRef.current = true;
        return () => (isMountedRef.current = false);
    }, []);

    return (
        <ITFABM
            handleSubmit={handleEdit}
            relacion={location?.state?.relacionITF}
            title="Editar relación entre títulos (ITF)"
            typeOfAction={typeOfActions.EDIT}
        />
    );
};

