import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { info, success } from "../../../store/alerts/alertActions";
import { routePaths, typeOfActions } from "../../../utils/Constants";
import { aprobarTituloITF } from "../ITFService";
import { ITFABM } from "./ITFABM";

export const ITFABMApproval = (props) => {
    let location = useLocation();
    const history = useHistory();

    const dispatch = useDispatch();

    const handleAprobar = async (user) => {
        try {
            const response = await aprobarTituloITF(location?.state?.relacionITF.relacionado.id);
            history.push(routePaths.ABM_TITULOS_ITF_SEARCH)
            dispatch(success("Se aprobo la relacion ITF correctamente."));
        }
        catch (err) {
            dispatch(info("No se pudo aprobar la relacion ITF."));
        }
    };

    return (
        <ITFABM
            handleSubmit={handleAprobar}
            relacion={location?.state?.relacionITF}
            title="Aprobar Relacion ITF"
            typeOfAction={typeOfActions.APPROVAL}
        />
    );
};
