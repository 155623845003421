import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { UnificacionAccordion } from './UnificadorAccordion';

export const UnificadorSearchRow = ({
    unificacion
}) => {

    return (
        <Accordion className="form-group">
            <Card key={unificacion.id}>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                    <Row>
                        <Col xs={12} md={8} lg={9} xl={10}>
                            <h5>{unificacion.terna.titulo.nombre} </h5>

                            <span className="accordion-header-text">
                                {unificacion.terna.procedencia.nombre}
                            </span>

                            <span className="accordion-header-text">
                                {unificacion.terna.resolucion.nombre}
                            </span>

                            {/* <span className="accordion-header-text">
                                {titulo[datos.primario].createdAt}
                            </span> */}
                        </Col>
                    </Row>
                </Accordion.Toggle>
                <UnificacionAccordion
                    unificacion={unificacion}
                />
            </Card>
        </Accordion>
    );
}
