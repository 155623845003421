import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import * as asignaturaService from "../../../asignaturas/AsignaturaService";
import { getEscuelasByVariantes } from '../../../escuela/EscuelaService';
import * as especialidadesService from "../../../especialidades/EspecialidadesService";
import Input from '../../../ui/Input';
import { handlerChange, removeUndefinedKeys } from '../../../utils/commons';
import { useDispatch } from 'react-redux';
import { error } from '../../../store/alerts/alertActions';

export const IncumbenciasDetailFormVariantes = ({
    cargo,
    elements,
    setterFunction,
    mainElement,
    autoAddVariante,
    actionButtonLabel,
    checkIsAvailableValidate = true,
    hideTipoEscuelaFilter,
    withoutAgregar,
}) => {
    const [areaSeleccionada, setAreaSeleccionada] = useState();
    const [tipoEscuelaSeleccionada, setTipoEscuelaSeleccionada] = useState();
    const [asignaturaSeleccionada, setAsignaturaSeleccionada] = useState();
    const [especialidadSeleccionada, setEspecialidadSeleccionada] = useState();

    const [opcionesAreas, setOpcionesAreas] = useState();
    const [opcionesEscuelas, setOpcionesEscuelas] = useState();
    const [opcionesAsignaturas, setOpcionesAsignaturas] = useState();
    const [opcionesEspecialidades, setOpcionesEspecialidades] = useState();

    const [isAvailable, setIsAvailable] = useState(false)
    const [alert, setAlert] = useState({ show: false, type: "", message: "" });

    const [inputsForm, setInputsForm] = useState({});
    const [isRefreshing, setIsRefreshing] = useState(false)
    const [resetForm, setResetForm] = useState(0)

    const dispatch = useDispatch();

    const handleReset = () => {
        setAreaSeleccionada(undefined)
        setAsignaturaSeleccionada(undefined)
        setEspecialidadSeleccionada(undefined)
        setOpcionesAreas(undefined)
        if (!hideTipoEscuelaFilter) {
            setTipoEscuelaSeleccionada(undefined)
            setOpcionesEscuelas(undefined)
        }
        setOpcionesAsignaturas(undefined)
        setOpcionesEspecialidades(undefined)
        setInputsForm({})
        setResetForm(st => st + 1)
        setIsRefreshing(false)
    }

    const filtrosDefault = {
        cargo_id: cargo.id,
        area_id: inputsForm.area_id,
        tipo_escuela_id: inputsForm.tipo_escuela_id,
        asignatura_id: inputsForm.asignaturas,
        especialidad_id: inputsForm.especialidad_id,
        limit: window.Configs.MAX_OPTIONS_FOR_SIMPLE_SELECTS,
    }

    const handleChange = (e) => {
        if (e.target.id == "area_id") setOpcionesAreas([])
        if (e.target.id == "tipo_escuela_id") setOpcionesEscuelas([])
        if (e.target.id == "asignatura_id") setOpcionesAsignaturas([])
        if (e.target.id == "especialidad_id") setOpcionesEspecialidades([])

        handlerChange(setInputsForm, inputsForm, null, e)
        refreshParentsInputs({ [e.target.id]: e.target.value })
    };

    const isVarianteInVariantesArray = (objectToFind, objsArray) => {
        const arrObjKeys = Object.keys(objectToFind);
        let posibles = [...objsArray];

        arrObjKeys.forEach(currentKey => {
            posibles = posibles.filter(currentObj => currentObj[currentKey].id == objectToFind[currentKey].id)
        })

        return (posibles.length > 0)
    }

    const getNewElement = () => {
        let info = {
            asignatura: {
                id: asignaturaSeleccionada?.value,
                nombre: asignaturaSeleccionada?.label,
                codDad: asignaturaSeleccionada?.codDad,
            },
            especialidad: {
                id: especialidadSeleccionada?.value,
                nombre: especialidadSeleccionada?.label,
                codDad: especialidadSeleccionada?.codDad,
            },
            area: {
                id: areaSeleccionada?.value,
                nombre: areaSeleccionada?.label,
            },
        }
        if (!hideTipoEscuelaFilter) info.tipoEscuela = {
            id: tipoEscuelaSeleccionada?.value,
            nombre: tipoEscuelaSeleccionada?.label,
        }

        return info
    }

    const addVariantes = (arrElements) => {
        setterFunction(() => {
            const newElement = getNewElement();
            return arrElements ? [...arrElements, newElement] : [newElement]
        })
    }

    const isFormCompleted = () => {
        return (
            asignaturaSeleccionada?.label &&
            especialidadSeleccionada?.label &&
            areaSeleccionada?.label &&
            (hideTipoEscuelaFilter || tipoEscuelaSeleccionada?.label)
        );
    }

    const checkIsAvailableOption = () => {
        setAlert({ show: false, type: "", message: "" })

        const newElement = getNewElement();

        if (checkIsAvailableValidate && isVarianteInVariantesArray(newElement, elements)) {
            setAlert({ show: true, type: "warning", message: "La variante ya se encuentra cargada" })
            return false
        }
        return true;
    }

    const handleClickAgregar = () => {
        let addedFlag = false;
        if (checkIsAvailableOption()) {
            addVariantes(elements);
            addedFlag = true;
        }
        setIsAvailable(false);
        return addedFlag;
    }

    const handleClickAgregarAndCleanEspeAsig = () => {
        const wasAdded = handleClickAgregar();
        if (wasAdded) {
            setEspecialidadSeleccionada(undefined)
            setAsignaturaSeleccionada(undefined)
            setOpcionesAsignaturas(undefined)
            setOpcionesEspecialidades(undefined)
            setInputsForm(st => ({
                area_id: st.area_id,
                tipo_escuela_id: st.tipo_escuela_id,
            }))

            setTimeout(() => {
                setResetForm(st => st + 1)
                setIsRefreshing(false)
            }, 200)
        }
    }





    const isRefreshRef = useRef(false)

    const objectRefreshList = {
        AREA: {
            isValidRefresh: areaSeleccionada?.value == undefined,
            endpoint: especialidadesService.getAreasByVariantes,
            response_elements_key: "data",
            param_key: "area_id",
            formated_object_key: "area",
            state: areaSeleccionada,
            opciones: opcionesAreas,
            setterValue: setAreaSeleccionada,
            setterOpciones: setOpcionesAreas,
        },
        TIPO_ESCUELA: {
            isValidRefresh: tipoEscuelaSeleccionada?.value == undefined && !hideTipoEscuelaFilter,
            endpoint: getEscuelasByVariantes,
            response_elements_key: "data",
            param_key: "tipo_escuela_id",
            formated_object_key: "tipoEscuela",
            state: tipoEscuelaSeleccionada,
            opciones: opcionesEscuelas,
            setterValue: setTipoEscuelaSeleccionada,
            setterOpciones: setOpcionesEscuelas,
        },
        ASIGNATURA: {
            isValidRefresh: asignaturaSeleccionada?.value == undefined,
            endpoint: asignaturaService.getAsignaturasByVariantes,
            response_elements_key: "elementos",
            param_key: "asignatura_id",
            formated_object_key: "asignatura",
            state: asignaturaSeleccionada,
            opciones: opcionesAsignaturas,
            setterValue: setAsignaturaSeleccionada,
            setterOpciones: setOpcionesAsignaturas,
        },
        ESPECIALIDAD: {
            isValidRefresh: especialidadSeleccionada?.value == undefined,
            endpoint: especialidadesService.getEspecialidadesByVariantes,
            response_elements_key: "data",
            param_key: "especialidad_id",
            formated_object_key: "especialidad",
            state: especialidadSeleccionada,
            opciones: opcionesEspecialidades,
            setterValue: setEspecialidadSeleccionada,
            setterOpciones: setOpcionesEspecialidades,
        },
    }

    const refreshList = Object.values(objectRefreshList);

    const formInputsLeft = refreshList.filter(stRefreshData => !stRefreshData.state).length
    const isOnlyOneInputLeft = formInputsLeft == 1
    const isOnlyAreaAndTipoEscuelInputsLeft = formInputsLeft == 2 && !objectRefreshList.AREA.state && !objectRefreshList.TIPO_ESCUELA.state

    const refreshParentsInputs = async (initialFilters = {}) => {
        const paramsFromCompletedInptus = {}
        refreshList.forEach(ctInput => {
            if (ctInput.state?.value != undefined) {
                paramsFromCompletedInptus[ctInput.param_key] = ctInput.state.value
            }
        })
        if (areaSeleccionada?.value == undefined || tipoEscuelaSeleccionada?.value == undefined || asignaturaSeleccionada?.value == undefined || especialidadSeleccionada?.value == undefined) {
            if (!isRefreshing && isRefreshRef.current == false) {
                setIsRefreshing(true)
                isRefreshRef.current = true
                const arrRefreshCallBacks = []

                let refreshedParams = { ...initialFilters, ...paramsFromCompletedInptus }
                const initialKey = Object.keys(initialFilters).length > 0 ? Object.keys(initialFilters)[0] : undefined

                const nextCb = async (index) => {
                    if (arrRefreshCallBacks.length >= index) await arrRefreshCallBacks[index + 1](index + 1)
                }

                refreshList.forEach(ctRefresh => {
                    if (ctRefresh.isValidRefresh && initialKey != ctRefresh.param_key) {
                        const refreshFunc = async (ctIndex) => {
                            const { cantidad, [ctRefresh.response_elements_key]: elementos } = await ctRefresh.endpoint(removeUndefinedKeys({ ...filtrosDefault, ...refreshedParams }));
                            if (cantidad == 1) {
                                ctRefresh.setterValue({ value: elementos[0].id, label: elementos[0].nombre })
                                setInputsForm(st => ({ ...st, [ctRefresh.param_key]: elementos[0].id }))
                                refreshedParams = { ...refreshedParams, [ctRefresh.param_key]: elementos[0].id }
                            }
                            if (cantidad <= window.Configs.MAX_OPTIONS_FOR_SIMPLE_SELECTS) ctRefresh.setterOpciones(elementos.map(ctOp => ({ value: ctOp.id, label: ctOp.nombre })))
                            await nextCb(ctIndex)
                        }
                        arrRefreshCallBacks.push(refreshFunc)
                    }
                })

                if (arrRefreshCallBacks.length > 0) {
                    const finishRefresh = () => {
                        setIsRefreshing(false)
                        isRefreshRef.current = false
                    }
                    arrRefreshCallBacks.push(finishRefresh)
                    await arrRefreshCallBacks[0](0)
                } else {
                    setIsRefreshing(false)
                    isRefreshRef.current = false
                }
            }
        }
    }

    const handleClickTodos = async () => {
        const getOpcionesAgregablesFromElement = async (element, defaultParams = {}, forceFetch) => {
            let opcionesAgregables = []
            if (element.opciones && !forceFetch) {
                opcionesAgregables = element.opciones.map(ctOp => ({ id: ctOp.value, nombre: ctOp.label, codDad: ctOp.codDad, }))
            } else {
                try {
                    let params = { limit: 99999, ...defaultParams }
                    refreshList.some(ctEl => {
                        if (!ctEl.state) return true;
                        params = { ...params, [ctEl.param_key]: ctEl.state.value }
                    })
                    setIsRefreshing(true)
                    const response = await element.endpoint(params)
                    opcionesAgregables = response[element.response_elements_key]
                } catch (err) {
                    dispatch(error('No se pudieron cargar los elementos disponibles para la funcion "Agregar todos"'))
                }
                finally {
                    setIsRefreshing(false)
                }
            }
            return opcionesAgregables
        }

        const baseElement = getNewElement();

        if (isOnlyOneInputLeft) {
            const emptyElement = refreshList.find(ctEl => !ctEl.state)
            const opcionesAgregables = await getOpcionesAgregablesFromElement(emptyElement)

            setterFunction(() => {
                const arrElementos = []
                opcionesAgregables.forEach(ctEl => {
                    const newElement = {
                        ...baseElement,
                        [emptyElement.formated_object_key]: {
                            id: ctEl.id,
                            nombre: ctEl.nombre,
                            codDad: ctEl.codDad,
                        }
                    }
                    if (checkIsAvailableValidate && !isVarianteInVariantesArray(newElement, elements)) {
                        arrElementos.push(newElement)
                    }
                })
                if (arrElementos.length == 0) setAlert({ show: true, type: "warning", message: "Las variantes ya se encuentran cargadas" })
                return [...elements, ...arrElementos];
            })
        } else if (isOnlyAreaAndTipoEscuelInputsLeft) {
            const areaElement = objectRefreshList.AREA;
            const tipoEscuelaElement = objectRefreshList.TIPO_ESCUELA;

            const opcionesAgregablesAreas = await getOpcionesAgregablesFromElement(areaElement);
            const arrOpcionesAgregablesTiposEscuelas = await Promise.all(opcionesAgregablesAreas.map(ctArea => getOpcionesAgregablesFromElement(tipoEscuelaElement, { area_id: ctArea.id }, true)))

            setterFunction(() => {
                const arrElementos = []
                opcionesAgregablesAreas.forEach((ctElArea, ctAreaIndex) => {
                    arrOpcionesAgregablesTiposEscuelas[ctAreaIndex].forEach(ctElTipoEscuela => {
                        const newElement = {
                            ...baseElement,
                            [areaElement.formated_object_key]: {
                                id: ctElArea.id,
                                nombre: ctElArea.nombre,
                                codDad: ctElArea.codDad,
                            },
                            [tipoEscuelaElement.formated_object_key]: {
                                id: ctElTipoEscuela.id,
                                nombre: ctElTipoEscuela.nombre,
                                codDad: ctElTipoEscuela.codDad,
                            },
                        }

                        if (checkIsAvailableValidate && !isVarianteInVariantesArray(newElement, elements)) {
                            arrElementos.push(newElement)
                        }
                    })
                })
                if (arrElementos.length == 0) setAlert({ show: true, type: "warning", message: "Las variantes ya se encuentran cargadas" })
                return [...elements, ...arrElementos];
            })
        }

    }

    useEffect(() => {
        if (autoAddVariante) {
            if (isFormCompleted()) {
                addVariantes();
            }
        }
        setIsAvailable(isFormCompleted());
    }, [areaSeleccionada, tipoEscuelaSeleccionada, asignaturaSeleccionada, especialidadSeleccionada])

    return (<>
        <Col>
            {alert.show && <Alert variant={alert.type}> {alert.message}</Alert>}
        </Col>
        <Col>
            <Row key={resetForm}>
                <Col sm={12} md={6} lg={6}>
                    <Input
                        type="react-select"
                        controlId="area_id"
                        name="area_id"
                        label="Area"
                        value={areaSeleccionada}
                        methodOnChange={handleChange}
                        elements={especialidadesService.getAreasByVariantes}
                        nameElementsResponse="data"
                        propertiesForFormat={{ value: "id", label: "nombre" }}
                        setterElementSelect={setAreaSeleccionada}
                        config={{ isSearchable: true, withAsyncDelay: true }}
                        paramsFilter={filtrosDefault}
                        syncElements={opcionesAreas}
                        isLoading={isRefreshing}
                        isDisabled={isRefreshing}
                        noCleanInNullRelated={true}
                        preloadRelatedFilters={true}
                        countCaracters={0}
                    ></Input>
                </Col>
                {!hideTipoEscuelaFilter &&
                    <Col Col sm={12} md={6} lg={6}>
                        <Input
                            type="react-select"
                            controlId="tipo_escuela_id"
                            name="tipo_escuela_id"
                            label="Tipo Escuela"
                            value={tipoEscuelaSeleccionada}
                            methodOnChange={handleChange}
                            elements={getEscuelasByVariantes}
                            nameElementsResponse="data"
                            propertiesForFormat={{ value: "id", label: "nombre" }}
                            setterElementSelect={setTipoEscuelaSeleccionada}
                            config={{ isSearchable: true, withAsyncDelay: true }}
                            paramsFilter={filtrosDefault}
                            syncElements={opcionesEscuelas}
                            isLoading={isRefreshing}
                            isDisabled={isRefreshing}
                            noCleanInNullRelated={true}
                            preloadRelatedFilters={true}
                            countCaracters={0}
                        ></Input>
                    </Col>
                }
                <Col sm={12} md={6} lg={6}>
                    <Input
                        type="react-select"
                        controlId="asignatura_id"
                        name="asignatura_id"
                        label="Asignaturas"
                        value={asignaturaSeleccionada}
                        methodOnChange={handleChange}
                        elements={asignaturaService.getAsignaturasByVariantes}
                        nameElementsResponse="elementos"
                        propertiesForFormat={{ value: "id", label: "nombre", codDad: "codDad" }}
                        setterElementSelect={setAsignaturaSeleccionada}
                        config={{ isSearchable: true, withAsyncDelay: true }}
                        paramsFilter={filtrosDefault}
                        syncElements={opcionesAsignaturas}
                        isLoading={isRefreshing}
                        isDisabled={isRefreshing}
                        noCleanInNullRelated={true}
                        preloadRelatedFilters={true}
                        countCaracters={0}
                    ></Input>
                </Col>
                <Col sm={12} md={6} lg={6}>
                    <Input
                        type="react-select"
                        controlId="especialidad_id"
                        name="especialidad_id"
                        label="Especialidades"
                        value={especialidadSeleccionada}
                        methodOnChange={handleChange}
                        elements={especialidadesService.getEspecialidadesByVariantes}
                        nameElementsResponse="data"
                        propertiesForFormat={{ value: "id", label: "nombre", codDad: "codDad" }}
                        setterElementSelect={setEspecialidadSeleccionada}
                        config={{ isSearchable: true, withAsyncDelay: true }}
                        paramsFilter={filtrosDefault}
                        syncElements={opcionesEspecialidades}
                        isLoading={isRefreshing}
                        isDisabled={isRefreshing}
                        noCleanInNullRelated={true}
                        preloadRelatedFilters={true}
                        countCaracters={0}
                    ></Input>
                </Col>
            </Row >
            {
                !autoAddVariante &&
                <Row style={{ paddingLeft: "15px" }}>
                    {/* <Col sm={12} md={6} lg={2} className="d-flex flex-column justify-content-center"> */}

                    {!withoutAgregar && <>
                        <Button
                            variant="primary"
                            content="Reporte"
                            type="submit"
                            onClick={handleClickTodos}
                            disabled={isRefreshing || !(isOnlyOneInputLeft || isOnlyAreaAndTipoEscuelInputsLeft)}
                        >
                            Agregar todos
                        </Button>
                        <Button
                            variant="primary"
                            content="Reporte"
                            type="submit"
                            onClick={handleClickAgregarAndCleanEspeAsig}
                            disabled={!isAvailable}
                        >
                            Agregar limpiando selección
                        </Button>
                    </>
                    }
                    <Button
                        variant="primary"
                        content="Reporte"
                        type="submit"
                        onClick={handleClickAgregar}
                        disabled={!isAvailable}
                    >
                        {actionButtonLabel}
                    </Button>
                    <Button
                        variant="secondary"
                        content="Reporte"
                        type="submit"
                        onClick={handleReset}
                        disabled={isRefreshing}
                    >
                        Limpiar selección
                    </Button>
                    {/* </Col> */}
                </Row>
            }
        </Col>
    </>
    )
}
