import React from "react";
import { Col, Row } from "react-bootstrap";
import Paginacion from "../../../generics/Paginacion";
import { UnificadorSearchRow } from "./UnificadorSearchRow";

export const UnificadorTable = ({
    elementos,
    cantidad,
    page,
    // changePage,
}) => {

    const renderTableRows = (elementos) => {
        return elementos.map((elemento) => {
            return (
                <React.Fragment key={elemento.id}>
                    <UnificadorSearchRow
                        // onSubmit={props.onSubmit}
                        unificacion={elemento}
                    ></UnificadorSearchRow>
                </React.Fragment>
            );
        });
    };


    return (
        <>
            <Row className="form-group">
                <Col>
                    {renderTableRows(elementos)}
                </Col>
            </Row>
            <Paginacion
                totalItems={cantidad}
                currentPage={page}
                onChangePage={() => { }}
            // onChangePage={(event, value) => {
            //   changePage(event, value);
            // }}
            />
        </>
    );
};
