import dateFormat from 'dateformat';

export const adaptUnificacionIncumbencia = (rawUnificacion) => {
    return {
        terna: {
            id: rawUnificacion.terna_destino_id,
            titulo: {
                id: rawUnificacion.terna_destino_titulo_id,
                nombre: rawUnificacion.terna_destino_titulo_nombre,
            },
            procedencia: {
                id: rawUnificacion.terna_destino_procedencia_id,
                nombre: rawUnificacion.terna_destino_procedencia_nombre,
            },
            resolucion: {
                id: rawUnificacion.terna_destino_resolucion_id,
                nombre: rawUnificacion.terna_destino_resolucion_nombre,
            },
        },
        unificaciones: rawUnificacion.unificaciones.map(ctUn => ({
            id: ctUn.id,
            terna: {
                id: ctUn.terna_origen_id,
                titulo: {
                    id: ctUn.terna_origen_titulo_id,
                    nombre: ctUn.terna_origen_titulo_nombre,
                },
                procedencia: {
                    id: ctUn.terna_origen_procedencia_id,
                    nombre: ctUn.terna_origen_procedencia_nombre,
                },
                resolucion: {
                    id: ctUn.terna_origen_resolucion_id,
                    nombre: ctUn.terna_origen_resolucion_nombre,
                },
            }
        }))
    }
}

export const adaptGetIncumbenciasByTerna = (arrIncumbencias) => {
    return arrIncumbencias.map(ctIncumbencia => {
        const created_at = dateFormat(ctIncumbencia.created_at, "dd/mm/yyyy");
        const concurrencia = Boolean(Number(ctIncumbencia.concurrencia));
        const activo = Boolean(Number(ctIncumbencia.activo));
        const ref_baja = ctIncumbencia.ref_baja;

        ctIncumbencia.titulo_terna2 = ctIncumbencia.titulo_terna_2;
        ctIncumbencia.titulo_terna3 = ctIncumbencia.titulo_terna_3;
        ctIncumbencia.estado_terna2 = ctIncumbencia.estado_terna_2;
        ctIncumbencia.estado_terna3 = ctIncumbencia.estado_terna_3;
        ctIncumbencia.suma_ot = Boolean(Number(ctIncumbencia.suma_ot));
        ctIncumbencia.apendice_baja = {
            id: ctIncumbencia.apendice_baja_id,
            nombre: ctIncumbencia.apendice_baja_nombre,
        }
        return {
            ...ctIncumbencia,
            created_at,
            concurrencia,
            activo,
            ref_baja
        };
    })
}

export const adaptGetIncumbenciasByTernaToInforme2 = (arrIncumbencias) => {
    return arrIncumbencias.map(ctElement => {
        let formatedResponse = {}
        formatedResponse.id = ctElement.incumbencia_id;
        formatedResponse.apendice = {
            id: ctElement.apendice_id,
            nombre: ctElement.apendice_nombre,
        };
        formatedResponse.activo = Boolean(Number(ctElement.activo));
        formatedResponse.acciones = ctElement.acciones;
        formatedResponse.folio = ctElement.folio;
        formatedResponse.puntaje = ctElement.puntaje;
        formatedResponse.createdAt = ctElement.created_at; //Ya viene adaptada del otro adapt, no hace falta format
        formatedResponse.calidad = ctElement.descripcion;
        formatedResponse.sumaOT = Boolean(Number(ctElement.suma_ot));

        formatedResponse.concurrencia = Boolean(Number(ctElement.concurrencia));
        formatedResponse.estado = {
            nombre: ctElement.estado,
            color: ctElement.estado_color,
        };

        formatedResponse.terna = ctElement.terna ? {
            id: ctElement.terna,
            titulo: { nombre: ctElement.titulo_terna },
            procedencia: { nombre: ctElement.procedencia_terna },
            resolucion: { nombre: ctElement.resolucion_completa_terna },
            regla: ctElement.regla_terna_1,
        } : null

        formatedResponse.terna2 = ctElement.terna2_id ? {
            id: ctElement.terna2_id,
            titulo: { nombre: ctElement.titulo_terna_2 },
            procedencia: { nombre: ctElement.procedencia_terna_2 },
            resolucion: { nombre: ctElement.resolucion_completa_terna_2 },
            regla: ctElement.regla_terna_2,
        } : null

        formatedResponse.terna3 = ctElement.terna3_id ? {
            id: ctElement.terna3_id,
            titulo: { nombre: ctElement.titulo_terna_3 },
            procedencia: { nombre: ctElement.procedencia_terna_3 },
            resolucion: { nombre: ctElement.resolucion_completa_terna_3 },
            regla: ctElement.regla_terna_3,
        } : null

        formatedResponse.cargo = {
            id: ctElement.cargo_id,
            nombre: ctElement.cargo,
            codDad: ctElement.cargo_cod_dad,
        }

        formatedResponse.asignatura = {
            id: ctElement.asignatura_id,
            nombre: ctElement.asignatura,
            codDad: ctElement.asignatura_cod_dad,
            nombreInvertido: null,
        }

        formatedResponse.especialidad = {
            nombre: ctElement.especialidad,
            codDad: ctElement.especialidad_cod_dad,
        }

        formatedResponse.area = {
            id: ctElement.area_id,
            nombre: ctElement.area_nombre,
            romano: ctElement.area_romano,
        }

        formatedResponse.tipoEscuela = {
            id: ctElement.id_tipo_escuela,
            nombre: ctElement.tipo_escuela,
        }
        formatedResponse.ref_baja = ctElement.ref_baja;

        return formatedResponse
    })
}

export const adaptGetIncumbenciasByTernaToIncumbenciasDetailModalTernas = (arrIncumbencias) => {
    return adaptGetIncumbenciasByTernaToInforme2(arrIncumbencias)
}

export const adaptCreatEditIncumbencia = (response) => {

    if (Object.prototype.toString.call(response) == '[object Object]') {
        const concurrenciasAgregados = response.concurrencias.agregados.map(ctElement => {
            return {
                ...ctElement,
                apendice: ctElement.apendice,
                folio: ctElement.folio,
                id: ctElement.terna_id,
                estado: ctElement.estado,
            }
        })

        response.concurrencias.agregados = concurrenciasAgregados;
    }

    return response
}

export const adaptGetIncumbenciasToInforme3 = (arrIncumbencias) => {
    return arrIncumbencias.map(ctElement => {
        let formatedResponse = {}

        formatedResponse.id = ctElement.incumbencia_id;
        formatedResponse.folio = ctElement.folio;
        formatedResponse.calidad = ctElement.condicion;
        formatedResponse.puntaje = ctElement.puntaje;
        formatedResponse.sumaOT = Boolean(Number(ctElement.suma_ot));
        formatedResponse.concurrencia = Boolean(Number(ctElement.concurrencia));

        formatedResponse.estado = {
            nombre: ctElement.estado,
            color: null,
        }

        formatedResponse.tipoEscuela = {
            nombre: ctElement.tipo_escuela,
            id: null,
        }

        formatedResponse.terna = ctElement.terna ? {
            ...ctElement.terna,
            regla: ctElement.terna?.regla?.descripcion,
        } : null

        formatedResponse.terna2 = ctElement.terna_2 ? {
            ...ctElement.terna_2,
            regla: ctElement.terna_2?.regla?.descripcion,
        } : null

        formatedResponse.terna3 = ctElement.terna_3 ? {
            ...ctElement.terna_3,
            regla: ctElement.terna_3?.regla?.descripcion,
        } : null

        return formatedResponse
    })
}

export const adaptGetIncumbencias = (arrIncumbencias, minInfo) => {
    return arrIncumbencias.map(ctElement => {
        let formatedResponse = {}
        console.log(ctElement)
        formatedResponse.acciones = ctElement.acciones;
        formatedResponse.activo = Boolean(Number(ctElement.activo));

        formatedResponse.apendice = ctElement.apendiceId ? {
            id: ctElement.apendiceId,
            nombre: ctElement.apendiceNombre,
        } : null;

        formatedResponse.area = ctElement.area ? {
            id: ctElement.area.id,
            nombre: ctElement.area.nombre,
        } : null;

        formatedResponse.asignatura = ctElement.asignatura;
        formatedResponse.asignaturasDelCargo = ctElement.asignaturasDelCargo?.map(ctAsig => ({
            ...ctAsig,
            especialidades: ctAsig.asignaturaCargoEspecialidades
        }));

        formatedResponse.cargo = ctElement.cargo ? {
            id: ctElement.cargo.id,
            nombre: ctElement.cargo.nombre,
            codDad: ctElement.cargo.codDad,
            asignaturas: minInfo ? [] : formatedResponse.asignaturasDelCargo,
            estado: ctElement.cargo.estado,
        } : null;

        formatedResponse.especialidad = ctElement.especialidad ? {
            id: ctElement.especialidad.id,
            nombre: ctElement.especialidad.nombre,
        } : null;

        formatedResponse.id = ctElement.incumbencia_id ?? ctElement.id;
        formatedResponse.folio = ctElement.folio;
        formatedResponse.calidad = ctElement.calidad;
        formatedResponse.puntaje = ctElement.puntaje;
        formatedResponse.sumaOT = Boolean(Number(ctElement.suma_ot));
        formatedResponse.apendiceNombre = ctElement.apendiceNombre;
        formatedResponse.concurrencia = Boolean(Number(ctElement.concurrencia));
        formatedResponse.estado = ctElement.estado;
        formatedResponse.tipoEscuela = ctElement.tipoEscuela ?
            ctElement.tipoEscuela
            :
            ctElement.tipo_escuela ?
                {
                    nombre: typeof (ctElement.tipo_escuela) == "string" ? ctElement.tipo_escuela : ctElement.tipo_escuela.nombre,
                    id: ctElement.tipo_escuela.id,
                }
                : null

        formatedResponse.terna = ctElement.terna ? {
            ...ctElement.terna,
            procedencia: {
                id: ctElement.terna.procedencia_id,
                nombre: ctElement.terna.procedencia_nombre,
            },
            resolucion: {
                id: ctElement.terna.resolucion_id,
                nombre: ctElement.terna.resolucion_nombre,
            },
            titulo: {
                id: ctElement.terna.titulo_id,
                nombre: ctElement.terna.titulo_nombre,
            },
        } : null

        formatedResponse.terna2 = ctElement.terna2 ? {
            ...ctElement.terna2,
            procedencia: {
                id: ctElement.terna2.procedencia_id,
                nombre: ctElement.terna2.procedencia_nombre,
            },
            resolucion: {
                id: ctElement.terna2.resolucion_id,
                nombre: ctElement.terna2.resolucion_nombre,
            },
            titulo: {
                id: ctElement.terna2.titulo_id,
                nombre: ctElement.terna2.titulo_nombre,
            },
        } : null

        formatedResponse.terna3 = ctElement.terna3 ? {
            ...ctElement.terna3,
            procedencia: {
                id: ctElement.terna3.procedencia_id,
                nombre: ctElement.terna3.procedencia_nombre,
            },
            resolucion: {
                id: ctElement.terna3.resolucion_id,
                nombre: ctElement.terna3.resolucion_nombre,
            },
            titulo: {
                id: ctElement.terna3.titulo_id,
                nombre: ctElement.terna3.titulo_nombre,
            },
        } : null

        return formatedResponse
    })
}

export const adaptPostIncumbenciasByCargo = (arrIncumbencias) => {
    return arrIncumbencias.map(ctElement => {
        let formatedResponse = {}
        formatedResponse.id = ctElement.incumbencia_id;
        formatedResponse.folio = ctElement.folio;
        formatedResponse.puntaje = ctElement.puntaje;
        formatedResponse.sumaOT = ctElement.suma_ot;
        formatedResponse.calidad = ctElement.tipo_incumbencia;

        formatedResponse.area = ctElement.area ? {
            id: null,
            nombre: ctElement.area,
        } : null;

        formatedResponse.apendice = ctElement.apendice ? {
            id: ctElement.apendice,
            nombre: null,
        } : null;

        formatedResponse.especialidad = ctElement.especialidad ? {
            id: null,
            nombre: ctElement.especialidad,
        } : null;

        formatedResponse.asignatura = ctElement.asignatura ? {
            id: ctElement.asignatura_id,
            nombre: ctElement.asignatura,
        } : null;

        formatedResponse.tipoEscuela = ctElement.tipo_escuela ? {
            nombre: ctElement.tipo_escuela,
            id: null,
        } : null

        formatedResponse.terna = ctElement.terna?.id ? {
            id: ctElement.terna.id,
            titulo: {
                id: ctElement.terna.titulo_id,
                nombre: ctElement.terna.titulo,
            },
            procedencia: {
                id: null,
                nombre: ctElement.terna.procedencia,
            },
            resolucion: {
                id: null,
                nombre: ctElement.terna.resolucion,
            },
            estado: ctElement.terna.estado,
        } : null

        formatedResponse.terna2 = ctElement.terna2?.id ? {
            id: ctElement.terna2.id,
            titulo: {
                id: ctElement.terna2.titulo_id,
                nombre: ctElement.terna2.titulo,
            },
            procedencia: {
                id: null,
                nombre: ctElement.terna2.procedencia,
            },
            resolucion: {
                id: null,
                nombre: ctElement.terna2.resolucion,
            },
            estado: ctElement.terna2.estado,
        } : null

        formatedResponse.terna3 = ctElement.terna3?.id ? {
            id: ctElement.terna3.id,
            titulo: {
                id: ctElement.terna3.titulo_id,
                nombre: ctElement.terna3.titulo,
            },
            procedencia: {
                id: null,
                nombre: ctElement.terna3.procedencia,
            },
            resolucion: {
                id: null,
                nombre: ctElement.terna3.resolucion,
            },
            estado: ctElement.terna3.estado,
        } : null

        formatedResponse.cargo = ctElement.cargo;

        return formatedResponse
    })
}