import React from 'react'
import CuadroSeleccion from '../../../../titulos/shared/CuadroSeleccion'
import { titulosIcon } from '../../../../utils/menuDashboardOptions'
import { UnificadorTernaRow } from '../table/UnificadorTernaRow'
import { UnificadorTernaTable } from '../table/UnificadorTernaTable'

export const UnificadorCardElements = ({
    principal,
    arrUnificar,
    onClickSeleccionarOriginal,
    onClickSeleccionarDestino,
    validated,
    removeTernaFromArrayUnificar,
}) => {

    const renderTableSelectTernaOriginal = (elementos) => {
        return <UnificadorTernaTable>
            {elementos.map((ctTerna, index) => <React.Fragment key={"ClonadorTernaRowOrigen-" + index}>
                <UnificadorTernaRow elemento={ctTerna} />
            </React.Fragment>)}
        </UnificadorTernaTable>
    }

    const renderTableSelectTernaUnificar = (elementos) => {
        return <UnificadorTernaTable>
            {elementos.map((ctTerna, index) => <React.Fragment key={"ClonadorTernaRowDestino-" + index}>
                <UnificadorTernaRow elemento={ctTerna} removeElement={removeTernaFromArrayUnificar} />
            </React.Fragment>)}
        </UnificadorTernaTable>
    }

    return (
        <>
            <div className="mb-3">
                <CuadroSeleccion
                    title={"Terna destino"}
                    secondButtonText={"Cambiar"}
                    icon={titulosIcon}
                    text={"Parece que todavía no hay una terna seleccionada"}
                    onClick={onClickSeleccionarOriginal}
                    validated={validated}
                    elementos={principal}
                    renderTable={(elementos) => renderTableSelectTernaOriginal(principal)}
                    ocultarBuscador={false}
                    firstButtonText={"Buscar"}
                />
            </div>
            <CuadroSeleccion
                title={"Ternas origen"}
                secondButtonText={"Cambiar/Agregar"}
                icon={titulosIcon}
                text={"Parece que todavía no hay una terna seleccionada"}
                onClick={onClickSeleccionarDestino}
                validated={validated}
                elementos={arrUnificar}
                renderTable={(elementos) => renderTableSelectTernaUnificar(elementos)}
                ocultarBuscador={false}
                firstButtonText={"Buscar"}
            />
        </>
    )
}

