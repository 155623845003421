import { useEffect, useRef, useState } from "react";
import { Badge, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Dialog from "../../../generics/dialog";
import { clear, error, info, success } from "../../../store/alerts/alertActions";
import { ScreenWithFabButtons } from "../../../ui/ScreenWithFabButtons";
import { relacionarTernas, unificarTernas } from "../../IncumbenciasService";
import { CustomTooltip } from "../../../ui/CustomTooltip";
import { SearchElement } from "./components/SearchElement";
import { UnificadorForm } from "./components/UnificadorForm";
import { UnificadorCardElements } from "./components/UnificadorCardElements";

export const tipoDocumentoUnificador = {
    PRINCIPAL: "principal",
    UNIFICAR: "a unificar",
}

export const UnificadorIncumbenciasABM = () => {
    const [selectDocument, setSelectDocument] = useState();
    const [principal, setPrincipal] = useState([]);
    const [arrUnificar, setArrUnificar] = useState([]);

    const [apendiceSeleccionado, setApendiceSeleccionado] = useState("")

    const [inputsForm, setInputsForm] = useState({})
    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);
    const formRef = useRef();

    const isPrincipalUnificarCompleted = () => {
        return principal.length > 0 && arrUnificar.length > 0
    }

    const handleSubmitUnificar = async () => {
        try {
            setValidated(true);
            if (!formRef.current?.checkValidity() === false && isPrincipalUnificarCompleted()) {
                const params = {
                    ...inputsForm,
                    "terna_origen_ids": arrUnificar.map(ctTerna => ctTerna.id),
                    "terna_destino_id": principal[0].id,
                }
                const resp = await unificarTernas(params);

                const cantidadNoAgregadasYaExistian = resp.data.no_agregadas;
                const cantidadNoAgregadasInvalidas = resp.data.invalidas;
                const cantidadNoAgregadas = cantidadNoAgregadasYaExistian + cantidadNoAgregadasInvalidas
                const cantidadAgregada = resp.data.agregadas;
                dispatch(success(
                    <><b>Se unificaron correctamente las ternas!</b><br></br><br></br>
                        Se deshabilitaron las ternas a unificar:<br></br>
                        {arrUnificar.map(ctTerna => <>
                            <b>Titulo:</b> {ctTerna.titulo.nombre} <br></br>
                            <b>Procedencia:</b> {ctTerna.procedencia.nombre} <br></br>
                            <b>Resolucion:</b> {ctTerna.resolucion.nombre} <br></br><br></br>
                        </>)}

                        Se agregaron {cantidadAgregada} incumbencias a la terna principal. <br></br>

                        No se agregaron {cantidadNoAgregadas} incumbencias a la terna principal. <br></br>
                    </>
                ))
                setArrUnificar([])
                setValidated(false);
            }
        } catch (err) {
            dispatch(error(err.response.data.message))
        }
    }

    const returnCallBack = () => {
        setSelectDocument("")
    }

    const isValidElementSelection = (newElemento) => {
        if (!newElemento) return "Debe seleccionar al menos un elemento."

        const isRepeated = () => {
            if (selectDocument == tipoDocumentoUnificador.PRINCIPAL && arrUnificar.length == 1 && arrUnificar[0].id == newElemento.id) return true
            if (selectDocument == tipoDocumentoUnificador.UNIFICAR && principal.length == 1 && principal[0].id == newElemento.id) return true
            return false
        }
        if (isRepeated()) return "El elemento original y el a unificar no pueden ser el mismo"
    }

    const handleClickSeleccionar = async (arrElemento, setIsLoading) => {
        dispatch(clear())
        if (selectDocument == tipoDocumentoUnificador.PRINCIPAL) {
            setPrincipal(arrElemento)
            const existeEnUnificar = arrUnificar.some(ctUnificar => ctUnificar.id == arrElemento[0].id)
            if (existeEnUnificar) {
                setArrUnificar(st => st.filter(ctUnificar => ctUnificar.id != arrElemento[0].id))
                dispatch(info("La terna seleccionada como principal ya se encontraba dentro de las ternas a unificar: Se removio de la lista de las ternas a unificar y se la agrego en la principal"))
            }
        }
        if (selectDocument == tipoDocumentoUnificador.UNIFICAR) {
            const existenteIndex = principal.length > 0 && arrElemento.findIndex(ctElemento => ctElemento.id == principal[0].id)
            if (existenteIndex >= 0) {
                dispatch(info("Una de las ternas a unificar ya se encuentra como terna principal: Se removio de la lista de las ternas a unificar"))
            }
            setArrUnificar(arrElemento.filter(ctElemento => ctElemento.id != principal[0].id))
        }
        // setWasModified(true);
        setSelectDocument("")
    }

    const onClickSeleccionarOriginal = () => {
        setSelectDocument(tipoDocumentoUnificador.PRINCIPAL)
    }

    const onClickSeleccionarDestino = () => {
        setSelectDocument(tipoDocumentoUnificador.UNIFICAR)
    }

    const removeTernaFromArrayUnificar = (terna) => {
        setArrUnificar(st => st.filter(ctTerna => ctTerna.id != terna.id))
    }

    const resetForm = () => {
        // setSelectDocument("")
        // setPrincipal([])
        // setArrUnificar([])
        // setValidated(false)
    }

    useEffect(() => {
        resetForm();
    }, [])

    return (
        <>
            {!selectDocument &&
                <ScreenWithFabButtons
                    hidePlusButton={true}
                    hideSearchButton={true}
                    containerFluid={true}
                >
                    <Row className="mx-auto">
                        <div className="container">
                            <div className="container">
                                <h1>Unificador de incumbencias</h1>
                            </div>
                            <Form
                                ref={formRef}
                                noValidate
                                validated={validated}
                                onSubmit={e => e.preventDefault()}
                            >
                                <UnificadorForm
                                    inputsForm={inputsForm}
                                    setInputsForm={setInputsForm}
                                    validated={validated}
                                    apendiceSeleccionado={apendiceSeleccionado}
                                    setApendiceSeleccionado={setApendiceSeleccionado}
                                />
                                <div className="container">
                                    <UnificadorCardElements
                                        removeTernaFromArrayUnificar={removeTernaFromArrayUnificar}
                                        principal={principal}
                                        arrUnificar={arrUnificar}
                                        validated={validated}
                                        onClickSeleccionarOriginal={onClickSeleccionarOriginal}
                                        onClickSeleccionarDestino={onClickSeleccionarDestino}
                                    />
                                </div>
                            </Form>
                        </div>
                    </Row>

                    {
                        // wasModified && (
                        <Row className="container mx-auto mt-4">
                            <Col>
                                <CustomTooltip text={"Unificar incumbencias de ternas a unificar en terna principal y dar de baja ternas a unificar"}>
                                    <Dialog
                                        action={handleSubmitUnificar}
                                        title={"Unificar ternas"}
                                        variant="primary"
                                        size="md"
                                        btnConfirmText="Unificar"
                                        btnVariant="secondary"
                                        btnText="Unificar Incumbencias"
                                        isLoadingText={"Unificando"}
                                        body={<span>¿Estás seguro de unificar las ternas seleccionadas?</span>}
                                    />
                                </CustomTooltip>
                            </Col>
                        </Row>
                        // )
                    }

                </ScreenWithFabButtons>
            }

            {selectDocument &&
                <SearchElement
                    elementos={tipoDocumentoUnificador.UNIFICAR == selectDocument ? arrUnificar : principal}
                    selectMulti={tipoDocumentoUnificador.UNIFICAR == selectDocument}
                    selectDocument={selectDocument}
                    handleClickSeleccionar={handleClickSeleccionar}
                    returnCallBack={returnCallBack}
                    isValidElementSelection={isValidElementSelection}
                />
            }
        </>
    )
}

