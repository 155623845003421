import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconButton from '../../generics/IconButton';
import Paginacion from '../../generics/Paginacion';
import { CustomTooltip } from '../../ui/CustomTooltip';
import { routePaths, tooltipsText } from '../../utils/Constants';

const FormTableHeader = () => {
  return (
    <tr>
      <th>Área</th>
      <th>Escuela</th>
      <th>Cargo</th>
      <th>Asignatura</th>
      <th>Especialidad</th>
      <th>Folio</th>
      <th></th>
    </tr>
  )
};

const FormRow = ({ element, viewDetail }) => {

  return (<tr>
    <td>{element.area ?? "-"}</td>
    <td>{element.nombre_escuela ?? "-"}</td>
    <td>{element.cargo ?? "-"}</td>
    <td>{element.asignatura ?? "-"}</td>
    <td>{element.especialidad ?? "-"}</td>
    <td>{element.folio ?? "-"}</td>
    <td>
      <CustomTooltip text={tooltipsText.INFORME}>
        <IconButton icon={faArrowRight} onClick={() => viewDetail(element)} />
      </CustomTooltip>
    </td>
  </tr>)
}

export const FormTableByAreaCargo = ({
  elementos,
  handleChangePage,
  cantidadElementos,
  viewDetail,
  page,
}) => {

  return (
    <>
      <Row className="form-group">
        <Col>
          <Table responsive>
            <thead>
              <FormTableHeader />
            </thead>
            <tbody>
              {elementos.map((currentElement, index) =>
                <React.Fragment key={`TernaPorAreaCargoRow-${index}`}>
                  <FormRow element={currentElement} viewDetail={viewDetail} />
                </React.Fragment>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Paginacion
        totalItems={cantidadElementos}
        currentPage={Number(page)}
        onChangePage={handleChangePage}
      />

    </>
  )
}
