import axios from "axios";
import { routePaths, apiURLs } from "../utils/Constants";
import { error } from "../store/alerts/alertActions";
import { history } from "../helpers/history";
import { logout } from "../authentication/AuthService"

const addInterceptor = (store) => {
  //Request Salientes
  axios.interceptors.request.use((request) => requestHandler(request));

  //Respone Entrante
  axios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  );

  const requestHandler = (request) => {
    return request;
  };

  const responseHandler = (resp) => {
    return resp;
  };
  let isRefreshing = false;
  let failedQueue = [];

  const resendFailedQueue = (newToken) => {
    failedQueue.forEach(currentFailed => {
      currentFailed.resolve(newToken)
    });
    failedQueue = [];
  }

  const addFetchToQueueAndWaitRefreshedToken = (originalRequest) => {
    originalRequest._retry = true;
    const newFetch = new Promise((resolve, reject) => {
      failedQueue.push({ originalRequest, resolve, reject });
    })
      .then(newToken => {
        originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
        return axios(originalRequest);
      })
      .catch(err => { return Promise.reject(err) });

    return newFetch;
  }

  const cleanAndRedirectLogin = (err) => {
    window.sessionStorage.removeItem('user');
    history.push('/login', { returnPath: history.location.pathname });
    return Promise.reject(err);
  }

  const sendRefreshFetch = async (user) => {
    if (user) {
      const refreshToken = user.refreshToken;
      try {
        const res = await axios.post(`${apiURLs.API_URL}/login/refreshToken`, { refreshToken: refreshToken })
        user.refreshToken = res.data.refreshToken;
        user.token = res.data.token;
        sessionStorage.setItem("user", JSON.stringify(user));
        resendFailedQueue(res.data.token)
      } catch (err) {
        store.dispatch(error('Tu sesión ha expirado. Vuelva a iniciar sesión para continuar'));
      } finally {
        isRefreshing = false;
      }
    }
  }

  const errorHandler = async (err) => {
    let originalRequest = err.config;
    if (!originalRequest._retry) {
      switch (err.response.status) {
        case 400:
          store.dispatch(error(err.response.message));
          break;
        case 401:
          const isExpiredTokenInRefreshTokenFetch = (err.response.data.message == 'expired_token' && err.response.config.url.includes('login/refreshToken'))
          console.log("PROBLEMA CON TOKEN, REENVIAMOS")
          if (isExpiredTokenInRefreshTokenFetch) {
            console.log("EXPIRO TOKEN")
            return cleanAndRedirectLogin(err)
          };

          //Is expired in other fetch => send RefreshTokenFetch
          if (isRefreshing) {
            return addFetchToQueueAndWaitRefreshedToken(originalRequest)
          };

          isRefreshing = true;

          const user = JSON.parse(sessionStorage.getItem("user"));
          sendRefreshFetch(user, originalRequest);
          return addFetchToQueueAndWaitRefreshedToken(originalRequest)

        case 403:
          history.push(routePaths.HOME);
          store.dispatch(error("No tiene permisos de acceso."));
          break;

        case 404:
          store.dispatch(error("El servidor no pudo encontrar el contenido solicitado."));
          break;

        default:
          store.dispatch(error(err.response.data.message));
          break;
      }
    }
    return Promise.reject(err);
  }
};


export default addInterceptor;