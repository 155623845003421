import { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Dialog from "../../../generics/dialog";
import { relacionarTernas } from "../../../incumbencias/IncumbenciasService";
import { clear, error, info, success } from "../../../store/alerts/alertActions";
import { CustomTooltip } from "../../../ui/CustomTooltip";
import { ScreenWithFabButtons } from "../../../ui/ScreenWithFabButtons";
import { SearchElement } from "./components/SearchElement";
import { UnificadorCardElements } from "./components/UnificadorCardElements";

export const tipoDocumentoUnificador = {
    BASE: "base",
    UNIFICAR: "a unificar",
}

export const UnificadorTernasABM = () => {
    const [selectDocument, setSelectDocument] = useState();
    const [principal, setPrincipal] = useState([]);
    const [arrUnificar, setArrUnificar] = useState([]);

    const [apendiceSeleccionado, setApendiceSeleccionado] = useState("")

    const [inputsForm, setInputsForm] = useState({})
    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);
    const formRef = useRef();

    const isPrincipalUnificarCompleted = () => {
        return principal.length > 0 && arrUnificar.length > 0
    }

    const handleSubmitRelacion = async () => {
        try {
            setValidated(true);
            if (!formRef.current?.checkValidity() === false && isPrincipalUnificarCompleted()) {
                const params = {
                    ...inputsForm,
                    "terna_destino_ids": arrUnificar.map(ctTerna => ctTerna.id),
                    "terna_origen_id": principal[0].id,
                }
                const resp = await relacionarTernas(params);

                dispatch(success(
                    <><b>Se crearon las relaciones entre la terna principal y las ternas a unificar correctamente!</b></>
                ))
                setArrUnificar([])
                setValidated(false);
            }
        } catch (err) {
            dispatch(error(err.response.data.message))
        }
    }

    const returnCallBack = () => {
        setSelectDocument("")
    }

    const isValidElementSelection = (newElemento) => {
        if (!newElemento) return "Debe seleccionar al menos un elemento."

        const isRepeated = () => {
            if (selectDocument == tipoDocumentoUnificador.BASE && arrUnificar.length == 1 && arrUnificar[0].id == newElemento.id) return true
            if (selectDocument == tipoDocumentoUnificador.UNIFICAR && principal.length == 1 && principal[0].id == newElemento.id) return true
            return false
        }
        if (isRepeated()) return "El elemento original y el a unificar no pueden ser el mismo"
    }

    const handleClickSeleccionar = async (arrElemento, setIsLoading) => {
        dispatch(clear())
        if (selectDocument == tipoDocumentoUnificador.BASE) {
            setPrincipal(arrElemento)
            const existeEnUnificar = arrUnificar.some(ctUnificar => ctUnificar.id == arrElemento[0].id)
            if (existeEnUnificar) {
                setArrUnificar(st => st.filter(ctUnificar => ctUnificar.id != arrElemento[0].id))
                dispatch(info("La terna seleccionada como principal ya se encontraba dentro de las ternas a unificar: Se removio de la lista de las ternas a unificar y se la agrego en la principal"))
            }
        }
        if (selectDocument == tipoDocumentoUnificador.UNIFICAR) {
            const existenteIndex = principal.length > 0 && arrElemento.findIndex(ctElemento => ctElemento.id == principal[0].id)
            if (existenteIndex >= 0) {
                dispatch(info("Una de las ternas a unificar ya se encuentra como terna principal: Se removio de la lista de las ternas a unificar"))
            }
            setArrUnificar(arrElemento.filter(ctElemento => ctElemento.id != principal[0].id))
        }
        // setWasModified(true);
        setSelectDocument("")
    }

    const onClickSeleccionarOriginal = () => {
        setSelectDocument(tipoDocumentoUnificador.BASE)
    }

    const onClickSeleccionarDestino = () => {
        setSelectDocument(tipoDocumentoUnificador.UNIFICAR)
    }

    const removeTernaFromArrayUnificar = (terna) => {
        setArrUnificar(st => st.filter(ctTerna => ctTerna.id != terna.id))
    }

    const resetForm = () => {
        // setSelectDocument("")
        // setPrincipal([])
        // setArrUnificar([])
        // setValidated(false)
    }

    useEffect(() => {
        resetForm();
    }, [])

    return (
        <>
            {!selectDocument &&
                <ScreenWithFabButtons
                    hidePlusButton={true}
                    hideSearchButton={true}
                    containerFluid={true}
                >
                    <Row className="mx-auto">
                        <div className="container">
                            <div className="container">
                                <h1>Unificador de ternas</h1>
                            </div>
                            <Form
                                ref={formRef}
                                noValidate
                                validated={validated}
                                onSubmit={e => e.preventDefault()}
                            >
                                <div className="container">
                                    <UnificadorCardElements
                                        removeTernaFromArrayUnificar={removeTernaFromArrayUnificar}
                                        principal={principal}
                                        arrUnificar={arrUnificar}
                                        validated={validated}
                                        onClickSeleccionarOriginal={onClickSeleccionarOriginal}
                                        onClickSeleccionarDestino={onClickSeleccionarDestino}
                                    />
                                </div>
                            </Form>
                        </div>
                    </Row>

                    {
                        // wasModified && (
                        <Row className="container mx-auto mt-4">
                            <Col>
                                <CustomTooltip text={"Generar relacion entre a unificar y principal. Las unificadas tendran las incumbencias de la principal"}>
                                    <Dialog
                                        action={handleSubmitRelacion}
                                        title={"Unificar ternas"}
                                        variant="primary"
                                        size="md"
                                        btnConfirmText="Unificar"
                                        btnVariant="secondary"
                                        btnText="Unificar ternas"
                                        isLoadingText={"Unificando"}
                                        body={<span>¿Estás seguro de unificar las ternas seleccionadas?</span>}
                                    />
                                </CustomTooltip>

                            </Col>
                        </Row>
                        // )
                    }

                </ScreenWithFabButtons>
            }

            {selectDocument &&
                <SearchElement
                    elementos={tipoDocumentoUnificador.UNIFICAR == selectDocument ? arrUnificar : principal}
                    selectMulti={tipoDocumentoUnificador.UNIFICAR == selectDocument}
                    selectDocument={selectDocument}
                    handleClickSeleccionar={handleClickSeleccionar}
                    returnCallBack={returnCallBack}
                    isValidElementSelection={isValidElementSelection}
                />
            }
        </>
    )
}

