import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { error } from '../../../store/alerts/alertActions';
import { cleanFab, searchFab } from '../../../store/screen/screenActions';
import * as ternaService from "../../../ternas/TernaService";
import Input from '../../../ui/Input';
import { handlerChange } from '../../../utils/commons';

export const UnificadorFormSearch = ({
    setElementos,
    filtros,
    onSubmit,
}) => {

    const [inputsForm, setInputsForm] = useState([]);
    const [tituloSeleccionado, setTituloSeleccionado] = useState("");
    const [procedenciaSeleccionada, setProcedenciaSeleccionada] = useState("");
    const [resolucionSeleccionada, setResolucionSeleccionada] = useState("");

    const isMountedRef = useRef(null);

    const dispatch = useDispatch();
    const screenState = useSelector(st => st.screen);

    const processSubmit = async () => {
        inputsForm["page"] = 1; //Para el paginado
        // cargarInputsFormConSeleccionados();
        // dispatch(saveFilters(inputsForm));
        await onSubmit(inputsForm).catch((err) => {
            dispatch(error(err.response.data.message));
        });
    };

    const handleChange = (e) => {
        handlerChange(setInputsForm, inputsForm, null, e);
    };

    const resetForm = () => {
        setElementos("")
        setInputsForm({});
        setProcedenciaSeleccionada("");
        setResolucionSeleccionada("");
        setTituloSeleccionado("");
    }

    useEffect(() => {
        if (screenState.clean) {
            resetForm()
            dispatch(cleanFab(false));
        }

        if (screenState.search) {
            processSubmit();
            dispatch(searchFab(false));
        }
    }, [screenState])

    return (
        <Row>
            <Col>
                <Form>
                    <Row>
                        <Col sm={12} md={6} lg={4}>
                            <Input
                                type="react-select"
                                controlId={"titulo"}
                                name="nombre"
                                label="Nombre de título"
                                value={tituloSeleccionado}
                                methodOnChange={handleChange}
                                elements={ternaService.getTitulos}
                                nameElementsResponse="elementos"
                                propertiesForFormat={{ value: "id", label: "nombre" }}
                                setterElementSelect={setTituloSeleccionado}
                                config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                                withBusquedaAvanzada={true}
                            ></Input>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Input
                                type="react-select"
                                controlId={"procedencia"}
                                name="procedencia"
                                label="Procedencia de título"
                                value={procedenciaSeleccionada}
                                methodOnChange={handleChange}
                                elements={ternaService.getProcedencias}
                                nameElementsResponse="elementos"
                                propertiesForFormat={{ value: "id", label: "nombre" }}
                                setterElementSelect={setProcedenciaSeleccionada}
                                config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                                withBusquedaAvanzada={true}
                            ></Input>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Input
                                type="react-select"
                                controlId={"resolucion"}
                                name="resolucion"
                                label="Resolución de título"
                                value={resolucionSeleccionada}
                                methodOnChange={handleChange}
                                elements={ternaService.getResoluciones}
                                nameElementsResponse="elementos"
                                propertiesForFormat={{ value: "id", label: "nombre" }}
                                setterElementSelect={setResolucionSeleccionada}
                                config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                                withBusquedaAvanzada={true}
                            ></Input>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    )
}
