
import { alertConstants } from '../../utils/Constants'

export const success = (message, config = {}) => {
    return {
        type: alertConstants.SUCCESS,
        message,
        scroll: config.scroll,
    }
}

export const error = (message, config = {}) => {
    return {
        type: alertConstants.ERROR,
        message,
        scroll: config.scroll,

    }
}

export const warning = (message, config = {}) => {
    return {
        type: alertConstants.WARNING,
        message,
        scroll: config.scroll,
    }
}

export const clear = (message) => {
    return {
        type: alertConstants.CLEAR
    }
}

export const hideOneAlert = (index) => {
    return {
        type: alertConstants.HIDE_ONE_ALERT,
        payload: index
    }
}

export const info = (message, config = {}) => {
    return {
        type: alertConstants.INFO,
        message,
        scroll: config.scroll,
    }
}

export const multiple = (arrAlerts, config = {}) => {
    return {
        type: alertConstants.MULTIPLE,
        message: arrAlerts,
        scroll: config.scroll,
    }
}