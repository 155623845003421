import React from "react";
import { Badge } from "react-bootstrap";
import Input from "../../../ui/Input";
import { tooltipsText } from "../../../utils/Constants";
import { CustomTooltip } from "../../../ui/CustomTooltip";
import TernaDetailModal from "../../../ternas/details/TernaDetailModal";
import { useDispatch } from "react-redux";
import { searchFab } from "../../../store/screen/screenActions";
import PrivateComponent from "../../../authentication/PrivateComponent";
import { PERMISOS_MAP } from "../../../utils/Permisos";
import DialogActivate from "../../../ui/DialogActivate";
import Dialog from "../../../generics/dialog";
import { faCheckDouble, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../generics/IconButton";

export const ITFSelectRow = ({
  titulo,
  onChangeSeleccionado,
  seleccionados,
  visibleCheckbox = false,
  visibleRadio = false,
  visibleInputs = false,
}) => {

  const dispatch = useDispatch();

  const resolveCallBack = () => {
    dispatch(searchFab(true))
  }

  return (
    <>
      <tr key={titulo.id}>
        {visibleCheckbox && (
          <td>
            <div key={titulo.id} className="form-checkbox-container">
              <Input
                type="checkbox"
                name="titulo"
                value={titulo.id}
                onChange={onChangeSeleccionado.bind(this, titulo)}
                defaultChecked={seleccionados.some(seleccionado => seleccionado.id == titulo.id)}
              ></Input>
            </div>
          </td>
        )}
        {visibleRadio && (
          <td>
            <div key={titulo.id} className="form-checkbox-container">
              <Input
                type="radio"
                name="titulo"
                className="option-row"
                value={titulo.id}
                onChange={onChangeSeleccionado.bind(this, titulo)}
                defaultChecked={false}
              ></Input>
            </div>
          </td>
        )}
        <td>{titulo.titulo.nombre}</td>
        <td>{titulo.procedencia.nombre}</td>
        <td>{titulo.resolucion.nombre}</td>
        <td>{titulo.titulo.nivel.nombre}</td>
        <td>{titulo.createdAt}</td>
        <td>
          <Badge variant={titulo.estado ? titulo.estado.color : "primary"}>
            {" "}
            {titulo.estado.nombre}
          </Badge>
        </td>
        <td>
          <div className="table-button-group">
            {/*
            {(titulo.acciones.alta || titulo.acciones.baja) &&
              <PrivateComponent permisos={titulo.acciones.alta ?
                PERMISOS_MAP.TITULO_ITF_ALTA
                :
                PERMISOS_MAP.TITULO_ITF_BAJA}>
                <CustomTooltip text={titulo.acciones.alta ? tooltipsText.ALTA : tooltipsText.BAJA}>
                  <DialogActivate
                    target={titulo}
                    title={(titulo.acciones.alta ? "Dar de alta" : "Dar de baja")}
                    action={changeState}
                    size="md"
                    body={<span>
                      ¿Estás seguro de dar de {(titulo.acciones.alta ? "alta " : "baja ")}
                      la relacion equivalente?
                    </span>
                    }
                    btnConfirmText={titulo.acciones.alta ? "Dar de alta" : "Dar de baja"}
                    isLoadingText={titulo.acciones.alta ? "Dando de alta" : "Dando de baja"}
                  >
                  </DialogActivate>
                </CustomTooltip>
              </PrivateComponent>
            }

            {
              titulo.acciones.eliminar &&
              <PrivateComponent permisos={PERMISOS_MAP.TITULO_ITF_ELIMINAR}>
                <CustomTooltip text={tooltipsText.ELIMINAR}>
                  <Dialog
                    action={handlerEliminar}
                    target={titulo}
                    title={"Eliminar relación"}
                    body={<span>
                      ¿Estás seguro que quiere eliminar la relación entre títulos?
                    </span>
                    }
                    size="md"
                    btnIcon={faTrash}
                    btnVariant={"danger"}
                    variant={"danger"}
                    btnConfirmText="Eliminar"
                    isLoadingText={"Eliminando"}
                  />
                </CustomTooltip>
              </PrivateComponent>
            }

            {titulo.acciones.aprobar &&
              <PrivateComponent permisos={PERMISOS_MAP.TITULO_ITF_APROBAR}>
                <CustomTooltip text={tooltipsText.APROBAR}>
                  <IconButton
                    icon={faCheckDouble}
                  // onClick={() => redirectToApproval(cargo)}
                  />
                </CustomTooltip>
              </PrivateComponent>
            }

            {titulo.acciones.publicar &&
              <PrivateComponent permisos={PERMISOS_MAP.TITULO_ITF_PUBLICAR}>
                <CustomTooltip text={tooltipsText.PUBLICAR}>
                  <TernaDetailModal
                    terna={titulo}
                    withPublicar={true}
                    onSubmit={resolveCallBack}
                  ></TernaDetailModal>
                </CustomTooltip>
              </PrivateComponent>
            }

            {titulo.acciones.editar &&
              <PrivateComponent PrivateComponent permisos={PERMISOS_MAP.TITULO_ITF_EDITAR}>
                <CustomTooltip text={tooltipsText.EDITAR}>
                  <IconButton icon={faPen} onClick={handlerEditar} />
                </CustomTooltip>
              </PrivateComponent>
            } */}


            <CustomTooltip text={tooltipsText.DETALLE}>
              <TernaDetailModal terna={titulo}></TernaDetailModal>
            </CustomTooltip>
          </div>
        </td>
      </tr>
    </>
  );
};