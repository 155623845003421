import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { info, success } from "../../../store/alerts/alertActions";
import { routePaths, typeOfActions } from "../../../utils/Constants";
import { EquivalenciaABM } from "./EquivalenciaABM";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { aprobarEquivalencia } from "../equivalenciaService";

export const EquivalenciaABMApproval = (props) => {
    let location = useLocation();
    // const [terna, setTerna] = useState();
    const [disabled, setDisabled] = useState(true);
    const history = useHistory();

    const dispatch = useDispatch();

    const handleAprobar = async (user) => {
        try {
            const response = await aprobarEquivalencia(location?.state?.relacionEquivalente.relacionado.id);
            history.push(routePaths.ABM_TITULOS_EQUIVALENTES_SEARCH)
            dispatch(success("Se aprobo la relacion equivalente correctamente."));
        }
        catch (err) {
            dispatch(info("No se pudo aprobar la relacion equivalente."));
        }
    };

    return (
        <EquivalenciaABM
            handleSubmit={handleAprobar}
            relacion={location?.state?.relacionEquivalente}
            title="Aprobar Equivalencia"
            typeOfAction={typeOfActions.APPROVAL}
        />
    );
};
