import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'

export const UnificadorTernaTable = ({
    children,
}) => {
    return (
        <>
            <Row className="form-group">
                <Col>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Nombre de título</th>
                                <th>Procedencia</th>
                                <th>Resolucion</th>
                                <th>Nivel</th>
                                <th>Fecha</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {children}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}

