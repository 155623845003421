import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import Input from "./Input";
import Select from 'react-select';
import { removeUndefinedKeys } from "../utils/commons";


const formatElements = (elementS, propertiesForFormat) => {
  const { value, label, codDad } = propertiesForFormat;
  const valueKeys = value.split(".");
  const labelKeys = label.split(".");
  const codDadKeys = codDad?.split(".");

  const extractValue = (element) => {
    let valueResp;
    let labelResp;
    let codDadResp;

    (valueKeys.length == 1) ?
      valueResp = element[valueKeys[0]] :
      valueResp = element[valueKeys[0]][valueKeys[1]];

    (labelKeys.length == 1) ?
      labelResp = element[labelKeys[0]] :
      labelResp = element[labelKeys[0]][labelKeys[1]];

    if (codDad) {
      (codDadKeys.length == 1) ?
        codDadResp = element[codDadKeys[0]] :
        codDadResp = element[codDadKeys[0]][codDadKeys[1]];
    }

    return [valueResp, labelResp, codDadResp];
  }

  if (Array.isArray(elementS))
    return elementS.map((element) => {
      const resp = extractValue(element)
      return { value: resp[0], label: resp[1], codDad: resp[2] };
    });

  else {
    const resp = extractValue(elementS)
    return { value: resp[0], label: resp[1], codDad: resp[2] };
  };
};

export const busquedaAvanzadaFlag = "_busquedaAvanzada"

const ReactSelect = ({
  value,
  methodOnChange,
  getByFilter,
  paramsFilter,
  propertiesForFormat,
  additional,
  controlId,
  isSearchable,
  isClearable,
  isMulti,
  setterElementSelect,
  nameElementsResponse,
  countCaracters,
  limitPerPage,
  addMenu,
  isDisabled,
  errorMessage,
  maxOptionsBreakAsyncToSync = window.Configs.MAX_OPTIONS_FOR_SIMPLE_SELECTS,
  syncElements,
  validated,
  required,
  isLoading,
  noOptionsMessage = () => "sin resultados",
  predictivoAsync,
  relatedFilters,
  defaultIndexSelectedOptionInSyncSelect,
  cleanRelatedFiltersRef,
  disableInputIfOnlyOneOption,
  initialValue,
  withBusquedaAvanzada,
  withAsyncDelay,
  ignoreRelated,
  noCleanInNullRelated,
  preloadRelatedFilters,
  style = {},
  styleContainer = {},
}) => {
  const defaultFetchDelayTime = 500
  const defaultMinCharsForSearch = 3

  const [numberOfOptions, setNumberOfOptions] = useState(0);
  const [allOptions, setAllOptions] = useState([]);
  const [isValid, setIsValid] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cacheUniqs, setCacheUniqs] = useState([])

  // const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
  //   const bottomBorder = (scrollHeight - clientHeight) / 2;
  //   return bottomBorder < scrollTop;
  // };

  const tipoInputRef = useRef();

  const tiposInput = {
    SYNC_OPCIONES_DESDE_FETCH_MENOR_A_BREAK: "SYNC_OPCIONES_DESDE_FETCH_MENOR_A_BREAK", //Se fetch las opciones desde aca y como no supera break => sync
    SYNC_FORZADO_OPCIONES_DESDE_PROPS: "SYNC_FORZADO_OPCIONES_DESDE_PROPS", //Se pasan opciones por props (no se hace fetch)
    SYNC_FORZADO: "SYNC_FORZADO", //Se piden opciones desde aca y se muestran todas (se ignora break)
    ASYNC_FORZADO: "ASYNC_FORZADO", //No se piden opciones desde aca, se piden a medida que se escribe (se ignora break)
    ASYNC_OPCIONES_DESDE_FETCH_MAYOR_A_BREAK: "ASYNC_OPCIONES_DESDE_FETCH_MAYOR_A_BREAK", //Se fetch las opciones desde aca y como superan break => async
  }

  const esOpcionBusquedaAvanzada = el => typeof (el?.label?.type) == "symbol"
  const makeOpcionBusquedaAvanzada = (searchValue) => ({ label: <><b>Busqueda avanzada: </b>{searchValue}</>, value: searchValue, isBusquedaAvanzada: true })

  const handleChangeSyncSelect = (e) => {
    if (e) {
      methodOnChange(
        {
          target: {
            ...e,
            id: controlId,
          }
        })
      if (setterElementSelect) setterElementSelect(e);
    } else {
      if (methodOnChange) {
        methodOnChange({ target: { id: controlId, value: "", label: "", isBusquedaAvanzada: true } });
      }
      if (setterElementSelect) setterElementSelect("");
    }
  }

  const handleChangeAsyncSelect = (...select) => {
    const elementoSeleccionado = select[0]

    if (elementoSeleccionado) {
      const elementoSeleccionado_value = elementoSeleccionado.value;
      const elementoSeleccionado_label = esOpcionBusquedaAvanzada(elementoSeleccionado) ? elementoSeleccionado.value : elementoSeleccionado.label;

      if (methodOnChange) {
        methodOnChange({
          target: {
            id: controlId,
            isBusquedaAvanzada: !!elementoSeleccionado.isBusquedaAvanzada,
            value: isMulti ? elementoSeleccionado : elementoSeleccionado_value,
            label: isMulti ? "" : elementoSeleccionado_label,
          },
        });
      }
      setterElementSelect(elementoSeleccionado);
    } else {
      if (methodOnChange) {
        methodOnChange({ target: { id: controlId, value: "", label: "", isBusquedaAvanzada: true } });
      }
      setterElementSelect("");
    }
  };

  const formatRelatedFilters = (filters) => {
    let formatedFilters = {};
    const filtersKeys = Object.keys(filters);

    if (filtersKeys.length > 0) {
      filtersKeys.forEach(ctKey => {
        if (filters[ctKey]) formatedFilters = { ...formatedFilters, [ctKey]: filters[ctKey] }
      })
    }
    return formatedFilters;
  }

  const handleSelectValueChange = (eventValue) => {
    if (eventValue) {
      setAllOptions(st => {
        const tieneOpcionBusquedaAvanzada = esOpcionBusquedaAvanzada(st[0])
        if (!tieneOpcionBusquedaAvanzada && withBusquedaAvanzada) return [makeOpcionBusquedaAvanzada(eventValue), ...st]
        return st.map(ctOpcion => (esOpcionBusquedaAvanzada(ctOpcion) && withBusquedaAvanzada ? makeOpcionBusquedaAvanzada(eventValue) : ctOpcion))
      })
    } else {
      setAllOptions(st => {
        const tieneOpcionBusquedaAvanzada = esOpcionBusquedaAvanzada(st[0])
        if (!tieneOpcionBusquedaAvanzada) return st
        return st.filter(ctOpcion => (!esOpcionBusquedaAvanzada(ctOpcion)))
      })
    }
  }

  const includeRelatedFilters = (filters = {}) => {
    let originalFilters = { ...filters }
    const formatedFilters = formatRelatedFilters(relatedFilters);
    const filtersKeys = Object.keys(formatedFilters)
    filtersKeys.forEach(ctKey => {
      if (Array.isArray(formatedFilters[ctKey])) {
        originalFilters = { ...originalFilters, [ctKey]: formatedFilters[ctKey].map(ctObject => ctObject.value) }
      } else {
        originalFilters = { ...originalFilters, [ctKey]: formatedFilters[ctKey] }
      }
    })
    return originalFilters
  }

  const loadOptions = async (search, prevOptions, { page }, ...props) => {
    const fetchNewOptios = async () => {
      const limit = Number.isInteger(limitPerPage) ? limitPerPage : 10;
      let response

      const paramsFilterSinUndefined = removeUndefinedKeys(paramsFilter)
      setLoading(true);
      let getFilters = {
        combo: true,
        nombre: search,
        ["nombre" + busquedaAvanzadaFlag]: true,
        numero: search,
        page: page || 1,
        limit: limit,
        all: true,
        ...paramsFilterSinUndefined,
      }
      if (relatedFilters) getFilters = includeRelatedFilters(getFilters)
      response = await getByFilter(getFilters)
      setLoading(false);

      const cantidad = response.cantidad;
      const pagina = response.page;
      const elements = nameElementsResponse ? response[nameElementsResponse] : response;

      let elementsFormated = propertiesForFormat ? formatElements(elements, propertiesForFormat) : elements

      if (withBusquedaAvanzada) {
        const tieneOpcionBusquedaAvanzada = prevOptions.find(ctOpcion => ctOpcion.value == search)
        if (!tieneOpcionBusquedaAvanzada) elementsFormated.unshift(makeOpcionBusquedaAvanzada(search))
      }
      return {
        options: elementsFormated,
        hasMore: cantidad ? cantidad > (pagina ? pagina : page) * limit : false,
        additional: {
          page: page + 1,
        },
      };
    }

    const charsForSearch = Number.isInteger(countCaracters) ? countCaracters : defaultMinCharsForSearch;
    const isOverMinChars = search.length < charsForSearch

    if (isOverMinChars && isSearchable) {
      const defaultResponseWithoutElements = {
        options: [],
        hasMore: false,
        additional: { page: page + 1, }
      }
      return defaultResponseWithoutElements
    }

    return await fetchNewOptios()
  }

  const CustomMenu = ({ innerRef, innerProps, isDisabled, children }) => {
    return !isDisabled ? (
      <div ref={innerRef} {...innerProps}>
        {children}
        <button
          className="btn btn-secondary btn-block"
          onClick={addMenu.onClick}
        >
          {addMenu.text}
        </button>
      </div>
    ) : null;
  };

  const loadComponent = addMenu ? { Menu: CustomMenu } : {};

  const getNumberOfOptions = async () => {
    const limit = predictivoAsync === false ? 9999 : maxOptionsBreakAsyncToSync;

    const paramsFilterSinUndefined = removeUndefinedKeys(paramsFilter)
    setLoading(true);
    let getFilters = {
      limit,
      combo: true,
      all: true,
      ["nombre" + busquedaAvanzadaFlag]: true,
      ...paramsFilterSinUndefined,
    }
    if (relatedFilters) getFilters = includeRelatedFilters(getFilters)
    const rawResp = await getByFilter(getFilters);
    setLoading(false);

    let arrOptions;
    // resp:
    // {cantidad: n, data: [n]}
    // {cantidad: n, elementos: [n]}
    // [n]

    const respType = Object.prototype.toString.call(rawResp)

    if (respType == "[object Array]") {
      if (propertiesForFormat) {
        arrOptions = formatElements(rawResp, propertiesForFormat)
        setAllOptions(arrOptions);
      } else {
        arrOptions = rawResp;
        setAllOptions(arrOptions)
      }
      setNumberOfOptions(rawResp.length)
    }

    if (respType == "[object Object]") {
      const respuesta = rawResp[nameElementsResponse]
      arrOptions = formatElements(respuesta, propertiesForFormat);
      setAllOptions(arrOptions);
      setNumberOfOptions(rawResp.cantidad);
    }

    if (!value && defaultIndexSelectedOptionInSyncSelect) {
      methodOnChange({ target: { id: controlId, value: arrOptions[defaultIndexSelectedOptionInSyncSelect].value, label: arrOptions[defaultIndexSelectedOptionInSyncSelect].label } })
      const opcionDefault = arrOptions[defaultIndexSelectedOptionInSyncSelect]
      setterElementSelect(opcionDefault)
    }

    if (arrOptions.length == 1) {
      methodOnChange({
        target: {
          id: controlId,
          value: isMulti ? [arrOptions[0]] : arrOptions[0].value,
          label: arrOptions[0].label,
          isBusquedaAvanzada: false
        }
      })
      setIsValid(true)
      setterElementSelect(isMulti ? [arrOptions[0]] : arrOptions[0])
    }
  }

  const validateRequiredInput = (event, handleChangeFunc) => {
    if (validated == true) {
      if (required == true) {

        const eventType = Object.prototype.toString.call(event)
        if (eventType == "[object Array]") {
          if (event?.length > 0) { setIsValid(true) } else { setIsValid(false) }
        } else if (eventType == "[object Object]") {
          if (event?.value) { setIsValid(true) } else { setIsValid(false) }
        } else {
          if (event) { setIsValid(true) } else { setIsValid(false) }
        }

      } else {
        setIsValid(true)
      }
    } else {
      setIsValid(null)
    }

    if (handleChangeFunc) handleChangeFunc(event)
  }

  const stylesSucces = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#a6a4a4 !important" : "#e5e5e5 !important",
      boxShadow: state.isFocused && 0,
    })
  }

  const stylesError = {
    control: (provided, state) => ({
      ...provided,
      borderColor: "var(--red) !important",
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(231 76 60 / 25%) !important" : 0,
    })
  }

  const prevRelatedFilters = useRef(preloadRelatedFilters ? relatedFilters : undefined);

  const isSameArray = (array1, array2) => {
    // Verificar si los arrays tienen la misma longitud
    if (!Array.isArray(array1) || !Array.isArray(array2) || array1.length !== array2.length) return false;

    // Ordenar los arrays por los ID de los objetos
    const sortedArray1 = array1.sort((a, b) => a.value - b.value);
    const sortedArray2 = array2.sort((a, b) => a.value - b.value);

    // Comparar los ID de los objetos en la misma posición
    for (let i = 0; i < sortedArray1.length; i++) {
      if (sortedArray1[i].value !== sortedArray2[i].value) {
        return false;
      }
    }

    // Los arrays tienen exactamente los mismos elementos
    return true;
  }

  const checkIsDistinctRelated = () => {
    const prevKeys = prevRelatedFilters.current ? Object.keys(prevRelatedFilters.current) : {}
    const newKeys = Object.keys(relatedFilters)

    if (prevKeys.length != newKeys.length) return true

    const cambioAlgunValue = prevKeys.some(ctPrevKey => {
      if (Array.isArray(relatedFilters[ctPrevKey])) {
        const newArray = [...relatedFilters[ctPrevKey]]
        const prevArray = prevRelatedFilters.current[ctPrevKey]
        if (newArray.length != prevArray.length) return true

        const isDistinct = !isSameArray(newArray, prevArray)
        return isDistinct
      } else {
        if (!relatedFilters[ctPrevKey] && noCleanInNullRelated) return false
        return relatedFilters[ctPrevKey] != prevRelatedFilters.current[ctPrevKey]
      }
      /*
      Si la relacion se limpia y queda null, NO se limpiara el relacionado, 
      ya que una relacion null habilita todas las opciones para el relacionado, entre ellas la seteada actualmente 
      */
    })

    return cambioAlgunValue
  }

  const inputCleanRef = useRef(false);

  const checkRelated = async () => {
    if (relatedFilters) {
      const isDistinctRelated = checkIsDistinctRelated();
      if (isDistinctRelated) {
        if (inputCleanRef.current == true) {
          setterElementSelect("")
          methodOnChange({ target: { id: controlId, value: "", label: "" } })
          setNumberOfOptions(0)
          setAllOptions([])
        };

        prevRelatedFilters.current = relatedFilters;

        if (predictivoAsync !== true && !isDisabled && getByFilter && !ignoreRelated) {
          await getNumberOfOptions();
        }
      }

      if (inputCleanRef.current == false && cleanRelatedFiltersRef?.current === true) {
        inputCleanRef.current = true;
      }
    }
  }

  const loadInput = async () => {
    if (predictivoAsync !== true && !isDisabled && getByFilter) {
      await getNumberOfOptions();
    }

    if (initialValue) {
      const initialFormated = formatElements(initialValue, propertiesForFormat)
      setterElementSelect(initialFormated);
    }

    if (relatedFilters) {
      setCacheUniqs(Object.values(relatedFilters))
    }
  }

  const separateTermsBusquedaCompuesta = (input) => {
    let arrIncludeTerms = [];
    let arrExcludeTerms = [];

    const statusTerm = {
      include: "INCLUDE",
      exclude: "EXCLUDE"
    }

    let statusFlag = statusTerm.include;
    let currentTerm = "";

    const cutAndResetCurrentTerm = () => {
      if (currentTerm.trim().length > 0) {
        if (statusFlag == statusTerm.include) {
          arrIncludeTerms.push(currentTerm)
        } else {
          arrExcludeTerms.push(currentTerm)
        }
      }
      currentTerm = "";
    }

    for (let i = 0; i < input.length; i++) {
      const ctString = input[i]
      if (ctString == "+") {
        cutAndResetCurrentTerm()
        statusFlag = statusTerm.include;
      } else if (ctString == "-") {
        cutAndResetCurrentTerm()
        statusFlag = statusTerm.exclude;
      } else {
        currentTerm = currentTerm + String(ctString)
      }
    }

    if (currentTerm) cutAndResetCurrentTerm()

    return { arrIncludeTerms, arrExcludeTerms }
  }

  const busquedaCompuestaFilter = (option, rawInput) => {
    if (esOpcionBusquedaAvanzada(option.data)) return true

    const input = rawInput.toLowerCase().trim();

    const { arrIncludeTerms, arrExcludeTerms } = separateTermsBusquedaCompuesta(input)

    const matchesIncludeTerms = arrIncludeTerms.every((term) =>
      option.label.toLowerCase().includes(term)
    );

    const matchesExcludeTerms = arrExcludeTerms.some((term) =>
      option.label.toLowerCase().includes(term)
    );

    return matchesIncludeTerms && !matchesExcludeTerms;
  }

  useEffect(() => {
    checkRelated();
  }, [relatedFilters])

  useEffect(() => {
    // if (cleanRelatedFiltersRef?.current === true) inputCleanRef.current = true;
    if (cleanRelatedFiltersRef?.current != inputCleanRef?.current) {
      inputCleanRef.current = cleanRelatedFiltersRef?.current;
    }
  }, [cleanRelatedFiltersRef])

  useEffect(() => {
    if (syncElements) {
      setAllOptions(syncElements)
      if (syncElements.length == 1) {
        handleChangeSyncSelect(syncElements[0])
        setIsValid(true)
      }
    } else {
      // if (predictivoAsync === true) {
      //   /* Si enviamos true es porque queremos forzar a que sea async (al escribir consulte al back las opciones que coinciden),
      //   sin averiguar siquiera cuantas opciones tiene (normalmente si no supera la cantidad maxima omite el async y descarga 
      //   todas las opciones) */
      //   return setNumberOfOptions(maxOptions + 1)
      // }
      loadInput()
    }
  }, [initialValue, syncElements])

  useEffect(() => {
    validateRequiredInput(value, false)
  }, [validated]);

  /* CON OPCIONES SYNC (NO PIDE ASYNC) */
  if (syncElements) {
    tipoInputRef.current = tiposInput.SYNC_FORZADO_OPCIONES_DESDE_PROPS
    return (
      <div className={`position-relative react-select-container`} style={styleContainer}>
        <Select
          value={value}
          onChange={(event) => { validateRequiredInput(event, handleChangeSyncSelect) }}
          options={syncElements}
          onInputChange={handleSelectValueChange}
          isDisabled={isDisabled}
          isLoading={isLoading}
          noOptionsMessage={noOptionsMessage}
          isClearable={isClearable}
          isSearchable={isSearchable}
          styles={
            isValid !== null ? (isValid === true ?
              { ...stylesSucces, ...style } :
              { ...stylesError, ...style }) :
              style
          }
          id={controlId}
          filterOption={busquedaCompuestaFilter}
        />
        <div className={`invalid-feedback mb-2 ${isValid == false && "d-block"}`} style={{ bottom: "-40px" }}>
          {errorMessage}
        </div>
      </div>
    )
  }

  /* FORZADO PREDICTIVO */
  if (predictivoAsync === true) {
    tipoInputRef.current = tiposInput.ASYNC_FORZADO
    return (
      <div className={`position-relative react-select-container`} style={styleContainer}>
        <AsyncPaginate
          value={value}
          onChange={(event) => { validateRequiredInput(event, handleChangeAsyncSelect) }}
          isClearable={isClearable}
          isSearchable={isSearchable}
          debounceTimeout={withAsyncDelay ? defaultFetchDelayTime : 0}
          isMulti={isMulti}
          loadOptions={loadOptions}
          additional={additional}
          isDisabled={isDisabled}
          cacheUniqs={cacheUniqs}
          components={loadComponent}
          placeholder=""
          noOptionsMessage={noOptionsMessage}
          styles={
            isValid !== null ? (isValid === true ?
              { ...stylesSucces, ...style } :
              { ...stylesError, ...style }) :
              style
          }
          id={controlId}
          isLoading={isLoading}
        />
        <div className={`invalid-feedback mb-2 ${isValid == false && "d-block"}`} style={{ bottom: "-40px" }}>
          {errorMessage}
        </div>
      </div>
    )
  }

  if (numberOfOptions == 0 || isDisabled) {
    return <Select
      value={value}
      isLoading={(loading && !isDisabled) || isLoading}
      isDisabled={true}
    />
  }

  /* FORZADO SYNC */
  if (predictivoAsync === false) {
    tipoInputRef.current = tiposInput.SYNC_FORZADO
    return (
      <div className={`position-relative react-select-container`} style={styleContainer}>
        <Select
          value={value}
          onChange={(event) => { validateRequiredInput(event, handleChangeAsyncSelect) }}
          isDisabled={isDisabled}
          isMulti={isMulti}
          options={allOptions}
          onInputChange={handleSelectValueChange}
          isClearable={isClearable}
          isSearchable={isSearchable}
          isLoading={loading || isLoading}
          noOptionsMessage={noOptionsMessage}
          styles={
            isValid !== null ? (isValid === true ?
              { ...stylesSucces, ...style } :
              { ...stylesError, ...style }) :
              style
          }
          id={controlId}
          filterOption={busquedaCompuestaFilter}
        />
        <div className={`invalid-feedback mb-2 ${isValid == false && "d-block"}`} style={{ bottom: "-40px" }}>
          {errorMessage}
        </div>
      </div>
    )
  }
  if (numberOfOptions > maxOptionsBreakAsyncToSync) {
    tipoInputRef.current = tiposInput.ASYNC_OPCIONES_DESDE_FETCH_MAYOR_A_BREAK
    return (
      <div className="position-relative react-select-container" style={styleContainer}>
        <AsyncPaginate
          value={value}
          onChange={(event) => { validateRequiredInput(event, handleChangeAsyncSelect) }}
          isClearable={isClearable}
          isSearchable={isSearchable}
          debounceTimeout={withAsyncDelay ? defaultFetchDelayTime : 0}
          isMulti={isMulti}
          loadOptions={loadOptions}
          additional={additional}
          isDisabled={isDisabled}
          cacheUniqs={cacheUniqs}
          isLoading={loading || isLoading}
          components={loadComponent}
          placeholder=""
          noOptionsMessage={noOptionsMessage}
          styles={
            isValid !== null ? (isValid === true ?
              { ...stylesSucces, ...style } :
              { ...stylesError, ...style }) :
              style
          }
          // onInputChange={handleInputChange}
          id={controlId}
        />
        <div className={`invalid-feedback mb-2 ${isValid == false && "d-block"}`} style={{ bottom: "-40px" }}>
          {errorMessage}
        </div>
      </div>
    )
  } else {
    tipoInputRef.current = tiposInput.SYNC_OPCIONES_DESDE_FETCH_MENOR_A_BREAK
    return (
      <div className="position-relative react-select-container" style={styleContainer}>
        <Select
          value={value}
          onChange={(event) => { validateRequiredInput(event, handleChangeAsyncSelect) }}
          isDisabled={isDisabled || (disableInputIfOnlyOneOption && allOptions.length <= 1)}
          isMulti={isMulti}
          onInputChange={handleSelectValueChange}
          isLoading={loading || isLoading}
          options={allOptions}
          isClearable={isClearable}
          isSearchable={isSearchable}
          noOptionsMessage={noOptionsMessage}
          styles={
            isValid !== null ? (isValid === true ?
              { ...stylesSucces, ...style } :
              { ...stylesError, ...style }) :
              style
          }
          id={controlId}
          filterOption={busquedaCompuestaFilter}
        />
        <div className={`invalid-feedback mb-2 ${isValid == false && "d-block"}`} style={{ bottom: "-40px" }}>
          {errorMessage}
        </div>
      </div>
    )
  }
};

export { ReactSelect, formatElements };
