import React, { useState } from 'react'
import Input from '../Input'

const LIMIT_OPTIONS = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
    { value: 250, label: 250 },
]

export const LimitSelect = ({
    handleChangeLimit,
    disabled,
    defaultValue = 10,
    label,
}) => {

    const [limit, setLimit] = useState(defaultValue ? { value: defaultValue, label: defaultValue } : LIMIT_OPTIONS[0])

    const handleChangeSelect = (e) => {
        setLimit(e)
        handleChangeLimit(e.value)
    }

    return (
        <div>
            <Input
                type="react-select-sync"
                controlId="limit"
                name="limit"
                label={label}
                value={limit}
                setterElementSelect={handleChangeSelect}
                methodOnChange={() => { }}
                disabled={disabled}
                // isLoading={!opcionesAreas}
                syncElements={LIMIT_OPTIONS}
                config={{ isSearchable: false, isClearable: false }}
            ></Input>
        </div>
    )
}
