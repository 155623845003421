import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { error } from '../../../../store/alerts/alertActions';
// import { getIncumbenciasByTerna } from '../../IncumbenciasService';
import { TernasSearch } from './TernasSearch';

// const agregarIncumbenciasDeTerna = async (terna) => {
//     const incumbencias = await getIncumbenciasByTerna({ terna_id: terna.id })
//     return {
//         ...terna,
//         cantidadIncumbencias: incumbencias.cantidad,
//         incumbenciasPrimerPagina: incumbencias.elementos,
//         incluir_relacionadas: false,
//     }
// }

export const SearchElement = ({
    elementos,
    selectDocument,
    handleClickSeleccionar,
    returnCallBack,
    isValidElementSelection,
    selectMulti,
}) => {
    
    const CANTIDAD_MAXIMA_TERNAS_A_UNIFICAR = 3;
    const dispatch = useDispatch();
    const [arrSelectedElements, setArrSelectedElements] = useState(elementos);

    const handleClickSeleccionarTernas = async (a, b, evento, setIsLoading) => {
        const validationErrorMsg = isValidElementSelection(arrSelectedElements);
        if (validationErrorMsg) return dispatch(error(validationErrorMsg));
        try {
            // setIsLoading(true);
            // const ternaConIncumbencias = await agregarIncumbenciasDeTerna(selectedElement)
            handleClickSeleccionar(arrSelectedElements, setIsLoading)
        } catch (err) {
            dispatch(error(err.response.data.message));
        } finally {
            // setIsLoading(false);
            setArrSelectedElements([])
        }
    }

    const handleChangeSeleccionar = (evento, elemento, setChecked) => {
        if (selectMulti) {
            setArrSelectedElements(st => {
                if (evento.target.checked) {
                    if (st.length >= CANTIDAD_MAXIMA_TERNAS_A_UNIFICAR) {
                        dispatch(error("La cantidad maxima de ternas a unificar es 3"))
                        return st
                    }
                    setChecked(true)
                    return [...st, elemento]
                } else {
                    setChecked(false)
                    return st.filter(ctSeleccionado => ctSeleccionado.id != elemento.id)
                }
            })
        } else {
            setArrSelectedElements([elemento])
        }
    }

    return (
        <TernasSearch
            elementos={arrSelectedElements}
            selectDocument={selectDocument}
            handleClickSeleccionar={handleClickSeleccionarTernas}
            returnCallBack={returnCallBack}
            handleChangeSeleccionar={handleChangeSeleccionar}
        />
    )
}