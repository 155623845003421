import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as apendiceService from "../../apendice/ApendiceService";
import PrivateComponent from "../../authentication/PrivateComponent";
import * as especialidadesService from "../../especialidades/EspecialidadesService";
import Dialog from "../../generics/dialog";
import { clear, error } from "../../store/alerts/alertActions";
import { CustomTooltip } from "../../ui/CustomTooltip";
import Input from "../../ui/Input";
import { Loader } from "../../ui/Loader";
import { routePaths, tooltipsText, typeOfActions } from "../../utils/Constants";
import { handlerChange } from "../../utils/commons";
import * as ternaService from "../TernaService";
import * as ternasService from "../TernaService";
import ProcedenciaAddModal from "../modals/ProcedenciaAddModal";
import ResolucionAddModal from "../modals/ResolucionAddModal";
import TituloAddModal from "../modals/TituloAddModal";
import { SeleccionarTernaNota } from "../modals/SeleccionarTernaNota";
import { validateReactInputs } from "../../helpers/validations";
import IconButton from "../../generics/IconButton";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TernasABMForm = ({ terna, typeOfAction = typeOfActions.CREATE, returnPath, ...props }) => {
  const [inputsForm, setInputsForm] = useState();
  const [nivelSeleccionado, setNivelSeleccionado] = useState("");
  const [tituloSeleccionado, setTituloSeleccionado] = useState("");
  const [procedenciaSeleccionada, setProcedenciaSeleccionada] = useState("");
  const [resolucionSeleccionada, setResolucionSeleccionada] = useState("");
  const [notaSeleccionada, setNotaSeleccionada] = useState("");
  const [notasOpcionesDefault, setNotasOpcionesDefault] = useState();

  const [isNotaCustom, setIsNotaCustom] = useState(false)

  const isMountedRef = useRef(null);
  const [validated, setValidated] = useState(false);
  const [wasModified, setWasModified] = useState(false);
  const cleanRelatedFiltersRef = useRef(false)

  const dispatch = useDispatch();
  const history = useHistory();

  const getCurrentApendice = async () => {
    if (typeOfAction == typeOfActions.CREATE) {
      apendiceService.getCurrentApendice().then(result => {
        if (isMountedRef.current) {
          setInputsForm(data => ({ ...data, apendice: result }))
        }
      });
    }
  };

  const handleAprobacion = async (event) => {
    const handleReturn = () => history.push(routePaths.ABM_TERNA_SEARCH)
    try {
      const resolve = async () => {
        await ternaService.approve(inputsForm, handleReturn)
      }
      await handleValidation(resolve);
    } catch (err) {
      handleReturn()
      dispatch(error("No fue posible aprobar la terna"))
    }
  };

  const handlePublish = (event) => {

    const resolve = async () => {
      history.push(routePaths.ABM_TERNA_SEARCH);
      ternaService.publish(inputsForm)
    }

    handleValidation(resolve);
  };

  const handleBorrador = async (event) => {
    try {

      const resolve = () => {
        history.push(routePaths.ABM_TERNA_SEARCH);
        ternaService.saveDraft()
      }

      await handleValidation(resolve);
    }
    catch (err) {
      dispatch(error(err.response.data.message))
    }
  };

  const handleGenerarTicketMod = async () => {
    console.log("GENERAR TICKET DE MODIFICACION DE TERNA")
  }

  const handleValidation = async (resolve) => {
    const form = document.getElementById("formCreateTerna");
    if (!form.checkValidity() === false) {
      const reactInputsIds = ["nivel", "titulo", "procedencia", "resolucion"];
      if (validateReactInputs(inputsForm, reactInputsIds)) {
        if (wasModified) {
          if (terna) {
            if (await ternaService.editTerna(inputsForm, resolve))
              setWasModified(false);
          } else {
            if (await ternaService.createNewTerna(inputsForm, resolve))
              setWasModified(false);
          }
        } else {
          await resolve(inputsForm);
        }
      }
    }
    setValidated(true);
  };

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, setWasModified, e)
  };

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const [showModalProcedencia, setShowModalProcedencia] = useState(false);
  const handleCloseModalProcedencia = () => setShowModalProcedencia(false);
  const handleGuardarProcedencia = (procedencia) => {

    setInputsForm({
      ...inputsForm,
      'procedencia': procedencia.id,
    });
    setProcedenciaSeleccionada({ label: procedencia.nombre, value: procedencia.id })
    setWasModified(true);
  }

  const [showModalResolucion, setShowModalResolucion] = useState(false);
  const handleCloseModalResolucion = () => setShowModalResolucion(false);
  const handleGuardarResolucion = (resolucion) => {
    console.log({ resolucion })
    setInputsForm({
      ...inputsForm,
      'resolucion': resolucion.id,
    });
    setResolucionSeleccionada({ label: resolucion.nombre, value: resolucion.id })
    setWasModified(true);
  }

  const [showModalTitulo, setShowModalTitulo] = useState(false);
  const handleShowModalTitulo = (action = "create") => {
    const actionOptions = ["create", "edit", false]
    if (actionOptions.every(ctAc => ctAc !== action)) return setShowModalTitulo(false)
    setShowModalTitulo(action)
  }

  const handleCloseModalTitulo = () => handleShowModalTitulo(false);

  const handleGuardarTitulo = (titulo) => {
    // TODO: Esta funcion esta asi (re mocha), porque al cambiar el nivel, el titulo detecta el cambio, y se pierde el titulo: id del inputsform

    setInputsForm(st => ({
      ...st,
      'nivel': titulo.nivel.id,
      'titulo': titulo.id,
    }));


    setNivelSeleccionado(st => {
      cleanRelatedFiltersRef.current = false;
      return ({ label: titulo.nivel.descripcion, value: titulo.nivel.id })
    })

    setTituloSeleccionado(st => {
      setInputsForm(st => ({
        ...st,
        'nivel': titulo.nivel.id,
        'titulo': titulo.id,
      }));
      cleanRelatedFiltersRef.current = false;
      return { label: titulo.nombre, value: titulo.id }
    })

    setWasModified(true);
  }

  const [showSeleccionarTernaModal, setShowSeleccionarTernaModal] = useState(false)

  const handleChangeNota = (event) => {
    if (event.target.value) {
      const isRequiredSeleccionarTitleA = notasOpcionesDefault.find(ctNota => ctNota.value == event.target.value)
      const isRequiredSeleccionarTitle = isRequiredSeleccionarTitleA.es_reemplazo;
      if (isRequiredSeleccionarTitle) setShowSeleccionarTernaModal(true)
      handleChange(event)
      setNotaSeleccionada({ ...event.target })
    } else {
      setInputsForm(st => ({ ...st, ...stateNotaReset }))
      setNotaSeleccionada("")
    }
    setWasModified(true);
  }

  const stateNotaReset = {
    nota_custom: null,
    nota_id: null,
    titulo_id_nota: null,
  }

  const handleCloseModalSeleccionarTerna = () => {
    setInputsForm(st => ({ ...st, ...stateNotaReset }))
    setNotaSeleccionada("")
    setShowSeleccionarTernaModal(false)
  }

  const handleSwitchNota = () => {
    setInputsForm(st => {
      let nwSt = { ...st, ...stateNotaReset }
      if (!isNotaCustom) delete nwSt.nota_id
      return nwSt
    })
    setNotaSeleccionada("")
    setIsNotaCustom(st => !st)
  }

  const handleSelectTitulo = ({ notaLabel, tituloSeleccionado }) => {
    const customEvent = {
      target: {
        id: "titulo_id_nota",
        value: tituloSeleccionado.value,
      }
    }

    handleChange(customEvent)
    setNotaSeleccionada(st => ({ ...st, label: notaLabel }))

    setShowSeleccionarTernaModal(false)
  }

  const loadNotasDefault = async () => {
    try {
      const response = await ternasService.getNotas()
      setNotasOpcionesDefault(response.elementos.map(ctOp => ({ value: ctOp.id, label: ctOp.descripcion, es_reemplazo: ctOp.es_reemplazo })))
    }
    catch (err) {
      dispatch(error("No se pudieron cargar las notas default correctamente"))
    }
  }

  const loadFormData = () => {
    if (terna) {
      const notaParams = {}
      if (terna.nota.id || terna.nota.es_nota_custom) {
        notaParams.es_nota_custom = terna.nota.es_nota_custom;
        if (terna.nota.es_nota_custom) {
          notaParams.nota_custom = terna.nota.descripcion;
          setIsNotaCustom(true)
        } else {
          notaParams.nota_id = terna.nota.id;
          if (terna.nota?.nota_titulo) notaParams.titulo_id_nota = terna.nota?.nota_titulo?.id;
          setNotaSeleccionada({ value: terna.nota.id, label: terna.nota.descripcion })
        }
      }

      const params = {
        ...terna,
        ...notaParams,
        nivel: terna.nivel.id,
        titulo: terna.titulo.id,
        procedencia: terna.procedencia.id,
        resolucion: terna.resolucion.id,
      }

      setInputsForm(params);
    } else {
      getCurrentApendice();
    }
  }

  useEffect(() => {
    isMountedRef.current = true;

    if (typeOfAction == typeOfActions.CREATE || typeOfAction == typeOfActions.EDIT) {
      cleanRelatedFiltersRef.current = true;
    }

    dispatch(clear());
    loadNotasDefault();
    loadFormData();
    return () => (isMountedRef.current = false);
  }, [terna]);

  return (
    <Row>
      <Col>
        {true ? (
          <Form
            id="formCreateTerna"
            noValidate
            validated={validated}
            onSubmit={preventDefault}
          >
            <Row>
              <Col md={6} lg={3}>
                <Input
                  type="react-select"
                  controlId="nivel"
                  name="nivel"
                  label="Nivel"
                  initialValue={terna ? terna.nivel : null}
                  value={nivelSeleccionado}
                  methodOnChange={handleChange}
                  elements={especialidadesService.getNivelesTerna}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "descripcion" }}
                  setterElementSelect={setNivelSeleccionado}
                  config={{ isSearchable: true, isClearable: true }}
                  validated={validated}
                  required
                  {...props}
                ></Input>
              </Col>
              <Col md={6} lg={3}>
                <Input
                  type="react-select"
                  controlId="titulo"
                  name="titulo"
                  label="Título"
                  initialValue={terna ? terna.titulo : null}
                  value={tituloSeleccionado}
                  methodOnChange={handleChange}
                  elements={ternasService.getTitulos}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setTituloSeleccionado}
                  config={{ isSearchable: true, isClearable: true }}
                  relatedFilters={inputsForm ? { nivel_id: inputsForm.nivel } : null}
                  disabled={inputsForm ? !inputsForm.nivel : true}
                  cleanRelatedFiltersRef={cleanRelatedFiltersRef}
                  style={{ marginBottom: "0.5rem" }}
                  validated={validated}
                  required
                  paramsFilter={{ creaTerna: true }}
                  {...props}
                ></Input>
                <div className="d-flex">
                  <button className="btn btn-primary flex-grow-1 mb-2" disabled={props.disabled} onClick={() => handleShowModalTitulo("create")}> Crear Título </button>

                  <CustomTooltip text={tooltipsText.EDITAR}>
                    <button className="btn btn-primary mb-2" disabled={!inputsForm?.titulo || props.disabled} onClick={() => handleShowModalTitulo("edit")} >
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                  </CustomTooltip>

                </div>
              </Col>
              <Col md={6} lg={3} >
                <Input
                  type="react-select"
                  controlId="procedencia"
                  name="procedencia"
                  label="Procedencia"
                  initialValue={terna ? terna.procedencia : null}
                  value={procedenciaSeleccionada}
                  methodOnChange={handleChange}
                  elements={ternasService.getProcedencias}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setProcedenciaSeleccionada}
                  config={{ isSearchable: true, isClearable: true }}
                  style={{ marginBottom: "0.5rem" }}
                  validated={validated}
                  required
                  paramsFilter={{ creaTerna: true }}
                  {...props}
                ></Input>
                <button className="btn btn-block btn-primary mb-2" disabled={props.disabled} onClick={setShowModalProcedencia}> Crear Procedencia </button>
              </Col>
              <Col md={6} lg={3}>
                <Input
                  type="react-select"
                  controlId="resolucion"
                  name="resolucion"
                  label="Resolución"
                  initialValue={terna ? terna.resolucion : null}
                  value={resolucionSeleccionada}
                  methodOnChange={handleChange}
                  elements={ternaService.getResolucionesCombo}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setResolucionSeleccionada}
                  config={{ isSearchable: true, isClearable: true }}
                  countCaracters={1}
                  style={{ marginBottom: "0.5rem" }}
                  validated={validated}
                  required
                  paramsFilter={{ creaTerna: true }}
                  {...props}
                ></Input>
                <button className="btn btn-block btn-primary mb-2" disabled={props.disabled} onClick={setShowModalResolucion}> Crear Resolución </button>
              </Col>
            </Row>
            <Row>
              <Col>
                {isNotaCustom ?
                  <Input
                    type="input"
                    controlId="nota_custom"
                    label="Nota personalizada"
                    value={inputsForm?.nota_custom ?? ""}
                    onChange={handleChange}
                    validated={validated}
                    disabled={props.disabled}
                  />
                  :
                  <Input
                    type="react-select-sync"
                    controlId="nota_id"
                    name="nota_id"
                    label="Nota"
                    value={notaSeleccionada}
                    methodOnChange={handleChangeNota}
                    syncElements={notasOpcionesDefault}
                    config={{ isSearchable: true, isClearable: true }}
                    validated={validated}
                    disabled={props.disabled}
                  ></Input>
                }
              </Col>
              <Col xs={2} style={{ maxWidth: '55px', paddingLeft: 0, margin: 0, }}>
                <CustomTooltip text={isNotaCustom ? tooltipsText.NOTA_DEFAULT : tooltipsText.NOTA_CUSTOM}>
                  <button
                    className="btn btn-secondary"
                    style={{ width: '100%', marginTop: "19px", height: "39px", display: "flex", justifyContent: "center", alignItems: "center" }}
                    disabled={props.disabled}
                    onClick={handleSwitchNota}>
                    {isNotaCustom ?
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" version="1.1" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6 1h10v2h-10v-2zM6 7h10v2h-10v-2zM6 13h10v2h-10v-2zM0 2c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM0 8c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM0 14c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2z"></path></svg>
                      :
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1.3em" width="1.3em" xmlns="http://www.w3.org/2000/svg"><path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm10 0h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM10 13H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm8 1h-2v2h-2v2h2v2h2v-2h2v-2h-2z"></path></svg>
                    }
                  </button>
                </CustomTooltip>
              </Col>
            </Row>

            <Row>
              <Col xs={4} md={2} xl={1}>
                <Input
                  type="input"
                  controlId="apendice"
                  label="Apéndice"
                  classNameLabel="text-muted"
                  onChange={handleChange}
                  readOnly
                  value={inputsForm?.apendice ? inputsForm.apendice.id : "---"}
                ></Input>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={12} lg={12}>
                <Input
                  type="textarea"
                  controlId="observaciones"
                  name="observaciones"
                  label="Observaciones"
                  rows={2}
                  value={inputsForm?.observaciones ? inputsForm.observaciones : ""}
                  onChangeTextArea={handleChange}
                  {...props}
                ></Input>
              </Col>
            </Row>
            <Row className="my-4">
              <Col>
                {wasModified && (typeOfAction == typeOfActions.GENERAR_TICKET_MOD ?
                  <PrivateComponent>
                    <Dialog
                      action={handleGenerarTicketMod}
                      target={terna}
                      title={"Generar ticket"}
                      variant="primary"
                      size="md"
                      btnConfirmText="Generar"
                      btnVariant="secondary"
                      btnText="Generar ticket"
                      body={<span>
                        ¿Estás seguro de generar el ticket de modificacion de la terna
                        <b> {inputsForm?.nombre ? inputsForm.nombre : ""}</b>
                        ?
                      </span>}
                    />
                  </PrivateComponent>
                  :
                  <PrivateComponent>
                    <Dialog
                      action={handleBorrador}
                      title={"Guardar como borrador"}
                      variant="primary"
                      size="md"
                      btnConfirmText="Guardar"
                      btnVariant="secondary"
                      btnText="Guardar como borrador"
                      isLoadingText={"Guardando"}
                      body={<span>
                        ¿Estás seguro de guardar como borrador la terna
                        <b> {inputsForm?.nombre ? inputsForm.nombre : ""}</b>
                        ?
                      </span>}
                    />
                  </PrivateComponent>
                )}

                {typeOfAction == typeOfActions.APPROVAL &&
                  <PrivateComponent>
                    <Dialog
                      action={handleAprobacion}
                      target={terna}
                      title="Confirmar aprobación"
                      variant="success"
                      size="md"
                      btnConfirmText="Aprobar"
                      btnText="Aprobar"
                      btnVariant="success"
                      body={<span>¿Estás seguro de aprobar la terna
                        <b> {inputsForm?.nombre ? inputsForm.nombre : ""}</b>?
                      </span>}
                      isLoadingText={"Aprobando"}
                    />
                  </PrivateComponent>
                }
              </Col>
            </Row>
            <Row>
              <Col>
                {false &&
                  <PrivateComponent>
                    <Dialog
                      action={handlePublish}
                      target={terna}
                      title="Publicar modificaciones"
                      size="md"
                      btnConfirmText="Confirmar y enviar"
                      btnText="Publicar modificaciones"
                      btnVariant="success"
                      body={
                        "Al publicar las modificaciones el cargo volverá " +
                        "a estar en estado Publicado con todas las modificaciones que hayas realizado"
                      }
                    />
                  </PrivateComponent>
                }
              </Col>
            </Row>
          </Form>
        ) : (
          <Loader></Loader>
        )}

        <SeleccionarTernaNota
          show={showSeleccionarTernaModal}
          closeModal={handleCloseModalSeleccionarTerna}
          setSelectedTitulo={handleSelectTitulo}
          nota={notaSeleccionada}
        />
        <TituloAddModal
          modalTitle={showModalTitulo == "create" ? "Crear Título" : "Editar Título"}
          nivel={nivelSeleccionado}
          titulo={showModalTitulo == "edit" ? tituloSeleccionado : null}
          show={showModalTitulo}
          handleClose={handleCloseModalTitulo}
          action={handleGuardarTitulo}
        />
        <ProcedenciaAddModal
          show={showModalProcedencia}
          handleClose={handleCloseModalProcedencia}
          action={handleGuardarProcedencia}
        />
        <ResolucionAddModal
          show={showModalResolucion}
          handleClose={handleCloseModalResolucion}
          action={handleGuardarResolucion}
        />
      </Col>
    </Row >
  );
};

export default TernasABMForm;
