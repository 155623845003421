import React from 'react'
import { Table } from 'react-bootstrap'
import Input from '../../ui/Input'

export const SincroTable = ({
    headersTable,
    elementos,
    tableKey,
    withCheckbox,
    handleChange,
    selectedChecks = {},
}) => {

    const wasAllSelect = () => {
        return elementos.every(ctEl => selectedChecks[ctEl.id])
    }

    const handleChangeAll = (e) => {
        handleChange(e, elementos)
    }

    return <Table responsive>
        <thead>
            <tr>
                {headersTable.map(ctHead => <th> {ctHead.label} </th>)}
                {withCheckbox && <th>
                    <Input
                        type="checkbox"
                        name="id"
                        id={`${tableKey}-checkbox`}
                        checked={wasAllSelect()}
                        onChange={handleChangeAll}
                        containerStyle={{ margin: 0, padding: 0 }}
                        groupStyle={{ margin: 0, padding: 0 }}
                        classNameLabel="d-flex justify-content-end"
                    ></Input>
                </th>}
            </tr>
        </thead>
        <tbody>
            {
                elementos.map((elemento, index) => {
                    return (
                        <tr key={`${tableKey}-rowElement-${index}`}>
                            {headersTable.map(ctHead => <td key={`${tableKey}-rowElement-${index}-${ctHead.key}`}>{elemento[ctHead.key]}</td>)}

                            {withCheckbox && <td>
                                <div key={elemento.id} className="form-checkbox-container">
                                    <Input
                                        type="checkbox"
                                        name="id"
                                        id={`${tableKey}-checkbox`}
                                        checked={!!selectedChecks[elemento.id]}
                                        onChange={(e) => handleChange(e, elemento)}
                                    ></Input>
                                </div>
                            </td>}
                        </tr>
                    )
                })
            }
        </tbody>
    </Table>
}
